// path / src/pages/admin/ViewAssignedLearningPaths.js

import React, { useState, useEffect } from 'react';
import axios from '../../config/axios'; // Updated path
import './ViewAssignedLearningPaths.css';

const ViewAssignedLearningPaths = () => {
  const [users, setUsers] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchUsersWithCreator = async () => {
      try {
        const token = sessionStorage.getItem('token'); // Retrieve the token from sessionStorage
        console.log('Retrieved token:', token); // Log the token to debug

        if (!token) {
          setError('No token found, please log in again.');
          return;
        }

        const response = await axios.get('/users/with-creator', {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        console.log('Response:', response.data); // Log the response to debug
        setUsers(response.data);
      } catch (error) {
        console.error('Error fetching users with creator:', error);
        setError('Error fetching users with creator');
      }
    };

    fetchUsersWithCreator();
  }, []);

  if (error) {
    return <div>{error}</div>;
  }

  if (!Array.isArray(users)) {
    return <div>Unexpected response format</div>;
  }

  return (
    <div className="container">
      <h1>Assigned Learning Paths</h1>
      <table>
        <thead>
          <tr>
            <th>Username</th>
            <th>Learning Paths</th>
            <th>Created By</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr key={user._id}>
              <td>{user.username}</td>
              <td>
                <ul>
                  {user.learningPaths.map((path, index) => (
                    <li key={`${user._id}-${path._id}-${index}`}>{path.title}</li>
                  ))}
                </ul>
              </td>
              <td>{user.createdBy ? user.createdBy.username : 'N/A'}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ViewAssignedLearningPaths;
