// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src.pages.EditLearningProgram.css */

.edit-learning-program-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.edit-learning-program-container h1 {
  text-align: center;
  margin-bottom: 20px;
}

.edit-learning-program-container label {
  display: block;
  margin-bottom: 10px;
}

.edit-learning-program-container input,
.edit-learning-program-container textarea {
  width: 100%;
  padding: 8px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.edit-learning-program-container button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.edit-learning-program-container button:hover {
  background-color: #0056b3;
}
`, "",{"version":3,"sources":["webpack://./src/pages/admin/EditLearningProgram.css"],"names":[],"mappings":"AAAA,sCAAsC;;AAEtC;EACE,gBAAgB;EAChB,cAAc;EACd,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,yBAAyB;AAC3B;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,mBAAmB;AACrB;;AAEA;;EAEE,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":["/* src.pages.EditLearningProgram.css */\r\n\r\n.edit-learning-program-container {\r\n  max-width: 600px;\r\n  margin: 0 auto;\r\n  padding: 20px;\r\n  border: 1px solid #ccc;\r\n  border-radius: 5px;\r\n  background-color: #f9f9f9;\r\n}\r\n\r\n.edit-learning-program-container h1 {\r\n  text-align: center;\r\n  margin-bottom: 20px;\r\n}\r\n\r\n.edit-learning-program-container label {\r\n  display: block;\r\n  margin-bottom: 10px;\r\n}\r\n\r\n.edit-learning-program-container input,\r\n.edit-learning-program-container textarea {\r\n  width: 100%;\r\n  padding: 8px;\r\n  margin-bottom: 20px;\r\n  border: 1px solid #ccc;\r\n  border-radius: 4px;\r\n}\r\n\r\n.edit-learning-program-container button {\r\n  width: 100%;\r\n  padding: 10px;\r\n  background-color: #007bff;\r\n  color: white;\r\n  border: none;\r\n  border-radius: 4px;\r\n  cursor: pointer;\r\n}\r\n\r\n.edit-learning-program-container button:hover {\r\n  background-color: #0056b3;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
