// path / src/pages/admin/CreateLearningPath.js

import React, { useState, useEffect } from 'react';
import axios from '../../config/axios';
import './CreateLearningPath.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CreateLearningPath = () => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [learningProgram, setLearningProgram] = useState('');
  const [learningPathId, setLearningPathId] = useState('');
  const [learningPrograms, setLearningPrograms] = useState([]);

  useEffect(() => {
    const fetchLearningPrograms = async () => {
      try {
        const response = await axios.get('/learning-programs');
        setLearningPrograms(Array.isArray(response.data) ? response.data : []);
      } catch (error) {
        console.error('Error fetching learning programs:', error);
        toast.error('Error fetching learning programs');
      }
    };

    fetchLearningPrograms();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const newPath = {
        title,
        description,
        learningProgram,
        learningPathId,
      };
      console.log('Creating learning path with data:', newPath);
      await axios.post('/admin/learning-paths', newPath);
      toast.success('Learning Path created successfully!');
      setTitle('');
      setDescription('');
      setLearningProgram('');
      setLearningPathId('');
    } catch (error) {
      console.error('Error creating learning path:', error);
      toast.error('Error creating learning path');
    }
  };

  return (
    <div className="create-learning-path-container">
      <h1>Create Learning Path</h1>
      <form onSubmit={handleSubmit} className="create-learning-path-form">
        <div className="form-group">
          <label htmlFor="title">Title</label>
          <input
            type="text"
            id="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="description">Description</label>
          <textarea
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="learningProgram">Learning Program</label>
          <select
            id="learningProgram"
            value={learningProgram}
            onChange={(e) => setLearningProgram(e.target.value)}
            required
          >
            <option value="" disabled>
              Select a learning program
            </option>
            {learningPrograms.map((program) => (
              <option key={program._id} value={program._id}>
                {program.title}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="learningPathId">Unique Learning Path ID</label>
          <input
            type="text"
            id="learningPathId"
            value={learningPathId}
            onChange={(e) => setLearningPathId(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="create-learning-path-button">
          Create
        </button>
      </form>
      <ToastContainer />
    </div>
  );
};

export default CreateLearningPath;
