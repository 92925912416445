// path / src/pages/admin/ViewProgress.js

import React, { useEffect, useState } from 'react';
import axios from '../../config/axios';
import './ViewProgress.css';

const ViewProgress = () => {
  const [progressData, setProgressData] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchProgressData = async () => {
      try {
        const response = await axios.get('/progress');
        setProgressData(response.data);
      } catch (error) {
        console.error('Error fetching progress data:', error);
        setError('Error fetching progress data');
      }
    };

    fetchProgressData();
  }, []);

  return (
    <div className="view-progress">
      <h1>Student Progress</h1>
      {error && <p>{error}</p>}
      <ul>
        {progressData.map((progress) => (
          <li key={progress._id}>
            <h2>{progress.studentName}</h2>
            <p>{progress.details}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ViewProgress;
