// path / src/pages/admin/ManageLearningPaths.js

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../../config/axios';
import './ManageLearningPaths.css';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import the default styles for the confirmation alert
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ManageLearningPaths = () => {
  const [learningPaths, setLearningPaths] = useState([]);
  const [learningPrograms, setLearningPrograms] = useState([]);
  const [lessons, setLessons] = useState([]);
  const [selectedPath, setSelectedPath] = useState(null);
  const [selectedLesson, setSelectedLesson] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [pathsResponse, programsResponse, lessonsResponse] = await Promise.all([
          axios.get('/admin/learning-paths'),
          axios.get('/learning-programs'),
          axios.get('/lessons'),
        ]);

        setLearningPaths(Array.isArray(pathsResponse.data) ? pathsResponse.data : []);
        setLearningPrograms(Array.isArray(programsResponse.data) ? programsResponse.data : []);
        setLessons(Array.isArray(lessonsResponse.data) ? lessonsResponse.data : []);
      } catch (error) {
        console.error('Error fetching data:', error);
        toast.error('Error fetching data. Please try again.');
      }
    };
    fetchData();
  }, []);

  const handleSelectPath = (event) => {
    const pathId = event.target.value;
    const path = learningPaths.find((p) => p._id === pathId);
    setSelectedPath(path);
  };

  const handleViewDetails = () => {
    const program = learningPrograms.find(p => p._id === selectedPath.learningProgram._id);
    toast.info(`Title: ${selectedPath.title}\nDescription: ${selectedPath.description}\nUnique ID: ${selectedPath.learningPathId}\nLearning Program: ${program ? program.title : 'Not Found'}`);
  };

  const handleEdit = () => {
    if (selectedPath) {
      navigate(`/admin-dashboard/edit-learning-path/${selectedPath._id}`, { state: { selectedPath } });
    } else {
      toast.warning('Please select a learning path to edit.');
    }
  };

  const handleDelete = async () => {
    confirmAlert({
      title: 'Confirm Deletion',
      message: 'Are you sure you want to delete this learning path?',
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            try {
              await axios.delete(`/admin/learning-paths/${selectedPath._id}`);
              setLearningPaths(learningPaths.filter(path => path._id !== selectedPath._id));
              setSelectedPath(null);
              toast.success('Learning Path deleted successfully!');
            } catch (error) {
              console.error('Error deleting learning path:', error);
              toast.error('Error deleting learning path. Please try again.');
            }
          }
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
    });
  };

  const handleLockUnlock = async () => {
    try {
      await axios.put(`/admin/learning-paths/lock-unlock/${selectedPath._id}`, { locked: !selectedPath.locked });
      setLearningPaths(learningPaths.map(path =>
        path._id === selectedPath._id ? { ...path, locked: !path.locked } : path
      ));
      setSelectedPath({ ...selectedPath, locked: !selectedPath.locked });
      toast.success(`Learning Path ${selectedPath.locked ? 'unlocked' : 'locked'} successfully!`);
    } catch (error) {
      console.error(`Error ${selectedPath.locked ? 'unlocking' : 'locking'} learning path:`, error);
      toast.error(`Error ${selectedPath.locked ? 'unlocking' : 'locking'} learning path. Please try again.`);
    }
  };

  const handleAddLesson = async () => {
    if (!selectedLesson) {
      toast.warning('You must select a Lesson first.');
      return;
    }

    if (!selectedPath) {
      toast.warning('You must select a Learning Path first.');
      return;
    }

    if (selectedPath.lessons.includes(selectedLesson)) {
      toast.warning('This lesson is already added to the selected learning path.');
      return;
    }

    try {
      await axios.post('/admin/learning-paths/add-lesson', { learningPathId: selectedPath._id, lessonId: selectedLesson });
      setSelectedPath({ ...selectedPath, lessons: [...selectedPath.lessons, selectedLesson] });
      toast.success('Lesson added to learning path successfully!');
    } catch (error) {
      console.error('Error adding lesson to learning path:', error);
      toast.error('Error adding lesson to learning path. Please try again.');
    }
  };

  const handleRemoveLesson = async (lessonId) => {
    try {
      await axios.post('/admin/learning-paths/remove-lesson', { learningPathId: selectedPath._id, lessonId });
      setSelectedPath({ ...selectedPath, lessons: selectedPath.lessons.filter(l => l !== lessonId) });
      toast.success('Lesson removed from learning path successfully!');
    } catch (error) {
      console.error('Error removing lesson from learning path:', error);
      toast.error('Error removing lesson from learning path. Please try again.');
    }
  };

  return (
    <div className="manage-learning-paths-container">
      <h1>Manage Learning Paths</h1>
      <label>
        Select Learning Path:
        <select onChange={handleSelectPath} value={selectedPath ? selectedPath._id : ''}>
          <option value="" disabled>Select a learning path</option>
          {learningPaths.map((path) => (
            <option key={path._id} value={path._id}>{path.title}</option>
          ))}
        </select>
      </label>

      {selectedPath && (
        <div className="path-details">
          <p><strong>Title:</strong> {selectedPath.title}</p>
          <p><strong>Description:</strong> {selectedPath.description}</p>
          <p><strong>Unique ID:</strong> {selectedPath.learningPathId}</p>
          <p><strong>Learning Program:</strong> {learningPrograms.find(p => p._id === selectedPath.learningProgram._id)?.title || 'Not Found'}</p>
          <p><strong>Status:</strong> {selectedPath.locked ? 'Locked' : 'Unlocked'}</p>
          <p><strong>Lessons:</strong></p>
          <ul>
            {selectedPath.lessons.map((lessonId) => {
              const lesson = lessons.find(l => l._id === lessonId);
              return (
                <li key={lessonId}>
                  {lesson ? lesson.title : 'Lesson Not Found'}
                  <button onClick={() => handleRemoveLesson(lessonId)}>Remove</button>
                </li>
              );
            })}
          </ul>
          <label>
            Add Lesson:
            <select onChange={(e) => setSelectedLesson(e.target.value)} value={selectedLesson}>
              <option value="" disabled>Select a lesson</option>
              {lessons.map((lesson) => (
                <option key={lesson._id} value={lesson._id}>{lesson.title}</option>
              ))}
            </select>
          </label>
          <button onClick={handleAddLesson}>Add Lesson</button>
          <button onClick={handleViewDetails}>View Details</button> 
          <button onClick={handleEdit}>Edit</button> 
          <button onClick={handleDelete}>Delete</button>
          <button onClick={handleLockUnlock}>{selectedPath.locked ? 'Unlock' : 'Lock'}</button>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default ManageLearningPaths;
