// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* path src.pages.admin.ViewStudents.css */

.view-students {
  padding: 20px;
}

.view-students h1 {
  font-size: 2em;
  margin-bottom: 20px;
}

.view-students ul {
  list-style-type: none;
  padding: 0;
}

.view-students li {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
}

.view-students a {
  margin-left: 10px;
  text-decoration: none;
  color: blue;
}

.view-students a:hover {
  text-decoration: underline;
}
`, "",{"version":3,"sources":["webpack://./src/pages/admin/ViewStudents.css"],"names":[],"mappings":"AAAA,0CAA0C;;AAE1C;EACE,aAAa;AACf;;AAEA;EACE,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;EACrB,UAAU;AACZ;;AAEA;EACE,mBAAmB;EACnB,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,iBAAiB;EACjB,qBAAqB;EACrB,WAAW;AACb;;AAEA;EACE,0BAA0B;AAC5B","sourcesContent":["/* path src.pages.admin.ViewStudents.css */\r\n\r\n.view-students {\r\n  padding: 20px;\r\n}\r\n\r\n.view-students h1 {\r\n  font-size: 2em;\r\n  margin-bottom: 20px;\r\n}\r\n\r\n.view-students ul {\r\n  list-style-type: none;\r\n  padding: 0;\r\n}\r\n\r\n.view-students li {\r\n  margin-bottom: 10px;\r\n  display: flex;\r\n  justify-content: space-between;\r\n}\r\n\r\n.view-students a {\r\n  margin-left: 10px;\r\n  text-decoration: none;\r\n  color: blue;\r\n}\r\n\r\n.view-students a:hover {\r\n  text-decoration: underline;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
