// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src.pages.ViewAssignedLearningPaths.css */
.container {
  padding: 20px;
  font-family: Arial, sans-serif;
}

h1 {
  text-align: center;
  margin-bottom: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

th, td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}

tr:nth-child(even) {
  background-color: #f9f9f9;
}

tr:hover {
  background-color: #f1f1f1;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  background-color: #eef;
  margin: 5px 0;
  padding: 5px;
  border-radius: 3px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/admin/ViewAssignedLearningPaths.css"],"names":[],"mappings":"AAAA,4CAA4C;AAC5C;EACE,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,yBAAyB;EACzB,mBAAmB;AACrB;;AAEA;EACE,sBAAsB;EACtB,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,qBAAqB;EACrB,UAAU;AACZ;;AAEA;EACE,sBAAsB;EACtB,aAAa;EACb,YAAY;EACZ,kBAAkB;AACpB","sourcesContent":["/* src.pages.ViewAssignedLearningPaths.css */\r\n.container {\r\n  padding: 20px;\r\n  font-family: Arial, sans-serif;\r\n}\r\n\r\nh1 {\r\n  text-align: center;\r\n  margin-bottom: 20px;\r\n}\r\n\r\ntable {\r\n  width: 100%;\r\n  border-collapse: collapse;\r\n  margin-bottom: 20px;\r\n}\r\n\r\nth, td {\r\n  border: 1px solid #ddd;\r\n  padding: 8px;\r\n  text-align: left;\r\n}\r\n\r\nth {\r\n  background-color: #f2f2f2;\r\n}\r\n\r\ntr:nth-child(even) {\r\n  background-color: #f9f9f9;\r\n}\r\n\r\ntr:hover {\r\n  background-color: #f1f1f1;\r\n}\r\n\r\nul {\r\n  list-style-type: none;\r\n  padding: 0;\r\n}\r\n\r\nli {\r\n  background-color: #eef;\r\n  margin: 5px 0;\r\n  padding: 5px;\r\n  border-radius: 3px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
