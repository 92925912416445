// Path: /src/config/axios.js

import axios from 'axios';

const instance = axios.create({
  baseURL: 'https://learningapp.glossabyek.com/api',  // API base URL
  headers: {
    'Content-Type': 'application/json',
  },
});

// Interceptor to attach token to every request
instance.interceptors.request.use(
  async (config) => {
    const token = sessionStorage.getItem('token');
    if (token) {
      try {
        const tokenExpiry = JSON.parse(atob(token.split('.')[1])).exp;
        const now = Math.floor(Date.now() / 1000);

        if (tokenExpiry < now) {
          // Token is expired; clear it
          sessionStorage.removeItem('token');
          console.warn('Token expired, removing from session storage.');
        } else {
          config.headers.Authorization = `Bearer ${token}`;  // Attach token
        }
      } catch (error) {
        console.error('Error processing token:', error);
        sessionStorage.removeItem('token');  // Clear token if processing fails
        throw error;
      }
    } else {
      console.warn('No token found in session storage.');
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Interceptor to handle 401 unauthorized responses
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      console.warn('Unauthorized access - removing token');
      sessionStorage.removeItem('token');
      window.location.href = '/login';  // Redirect to login
    }
    return Promise.reject(error);
  }
);

export default instance;
