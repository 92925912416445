// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* path.src.pages.admin.StudentProgress.css */

.student-progress {
  padding: 20px;
}

.student-progress h1 {
  font-size: 2em;
  margin-bottom: 20px;
}

.student-progress ul {
  list-style-type: none;
  padding: 0;
}

.student-progress li {
  margin-bottom: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/admin/StudentProgress.css"],"names":[],"mappings":"AAAA,6CAA6C;;AAE7C;EACE,aAAa;AACf;;AAEA;EACE,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;EACrB,UAAU;AACZ;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":["/* path.src.pages.admin.StudentProgress.css */\r\n\r\n.student-progress {\r\n  padding: 20px;\r\n}\r\n\r\n.student-progress h1 {\r\n  font-size: 2em;\r\n  margin-bottom: 20px;\r\n}\r\n\r\n.student-progress ul {\r\n  list-style-type: none;\r\n  padding: 0;\r\n}\r\n\r\n.student-progress li {\r\n  margin-bottom: 10px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
