// Path: /src/components/MediaViewer/VideoMediaViewer.js

import React, { useState, useEffect, useRef } from 'react';
import axios from '../../config/axios';
import './VideoMediaViewer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause, faStop } from '@fortawesome/free-solid-svg-icons';

const VideoMediaViewer = ({ media, onClose }) => {
  const [notes, setNotes] = useState(media.notes || []);
  const [noteContent, setNoteContent] = useState('');
  const [lastPauseTime, setLastPauseTime] = useState(null);
  const notesRef = useRef(null);
  const videoRef = useRef(null);
  const noteTextareaRefs = useRef({});

  const formatTime = (time) => {
    const date = new Date(0);
    date.setSeconds(time);
    return date.toISOString().substr(11, 8); // Format as hh:mm:ss
  };

  const handleAddNote = async () => {
    if (noteContent.trim() === '') return;

    try {
      const response = await axios.post(`/media/${media._id}/notes`, { content: noteContent });
      setNotes(response.data.notes);
      setNoteContent('');
    } catch (error) {
      console.error('Error adding note', error);
    }
  };

  const handleUpdateNote = async (noteId, newContent) => {
    if (newContent.trim() === '') return;

    try {
      await axios.put(`/media/${media._id}/notes/${noteId}`, { content: newContent });
      setNotes((prevNotes) =>
        prevNotes.map((note) =>
          note._id === noteId ? { ...note, content: newContent } : note
        )
      );
    } catch (error) {
      console.error('Error updating note', error);
    }
  };

  const handleNoteChange = (noteId, newContent) => {
    setNotes((prevNotes) =>
      prevNotes.map((note) =>
        note._id === noteId ? { ...note, content: newContent } : note
      )
    );
  };

  const handleSaveNotes = async () => {
    for (const note of notes) {
      try {
        await axios.put(`/media/${media._id}/notes/${note._id}`, { content: note.content });
      } catch (error) {
        console.error('Error saving note', error);
      }
    }
  };

  const handleCloseViewer = async () => {
    await handleSaveNotes();
    if (noteContent.trim() !== '') {
      await handleAddNote();
    }
    onClose();
  };

  const handleDeleteNote = async (noteId) => {
    try {
      await axios.delete(`/media/${media._id}/notes/${noteId}`);
      setNotes((prevNotes) => prevNotes.filter((note) => note._id !== noteId));
    } catch (error) {
      console.error('Error deleting note', error);
    }
  };

  const handleMinimizeNote = (noteId) => {
    setNotes((prevNotes) =>
      prevNotes.map((note) =>
        note._id === noteId ? { ...note, minimized: !note.minimized } : note
      )
    );
  };

  useEffect(() => {
    const handleScroll = () => {
      if (notesRef.current) {
        const notesSection = notesRef.current;
        const mediaContent = document.querySelector('.media-content');
        const mediaContentRect = mediaContent.getBoundingClientRect();
        const topPosition = Math.max(20, mediaContentRect.top);
        notesSection.style.top = `${topPosition}px`;
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const autoResizeTextarea = (e) => {
    e.target.style.height = 'auto';
    e.target.style.height = `${e.target.scrollHeight}px`;
  };

  useEffect(() => {
    const textareas = document.querySelectorAll('textarea');
    textareas.forEach((textarea) => {
      autoResizeTextarea({ target: textarea });
    });
  }, [notes]);

  const handlePlay = () => {
    console.log("Attempting to play video from URL: ", videoRef.current.src);
    videoRef.current.play().catch((error) => {
      console.error("Error playing video:", error);
    });
  };

  const handlePause = () => {
    videoRef.current.pause();
    setLastPauseTime(new Date().getTime());
  };

  const handleStop = () => {
    videoRef.current.pause();
    videoRef.current.currentTime = 0;
  };

  const handleNoteFocus = (noteId, ref) => {
    const currentTime = new Date().getTime();
    if (lastPauseTime && currentTime - lastPauseTime <= 4000) {
      const videoCurrentTime = formatTime(videoRef.current.currentTime);
      const notePrefix = `${media.originalFile}, ${videoCurrentTime}: `;

      const textarea = ref.current;
      const startPos = textarea.selectionStart;
      const newValue = textarea.value.substring(0, startPos) + notePrefix + textarea.value.substring(startPos);
      
      textarea.value = newValue;
      textarea.setSelectionRange(startPos + notePrefix.length, startPos + notePrefix.length);

      if (noteId === 'new') {
        setNoteContent(newValue);
      } else {
        setNotes((prevNotes) =>
          prevNotes.map((note) =>
            note._id === noteId ? { ...note, content: newValue } : note
          )
        );
      }
    }
  };

  const videoSrc = `https://learningapp.glossabyek.com/uploads/${media.data.replace(/^\/?uploads\//, '')}`;

  console.log('Video URL:', videoSrc); // Log the video URL

  return (
    <div className="media-viewer-wrapper">
      <div className="media-content">
        <h3>{media.title}</h3>
        <p>{media.description}</p>
        <video ref={videoRef} src={videoSrc} controls>
          <source src={videoSrc} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="controls">
          <button onClick={handlePlay}>
            <FontAwesomeIcon icon={faPlay} />
          </button>
          <button onClick={handlePause}>
            <FontAwesomeIcon icon={faPause} />
          </button>
          <button onClick={handleStop}>
            <FontAwesomeIcon icon={faStop} />
          </button>
        </div>
        <div className="close-button-container">
          <button onClick={handleCloseViewer}>Close</button>
        </div>
      </div>

      <div className="notes-section" ref={notesRef}>
        <h4>Notes</h4>
        <ul>
          {notes.map((note) => (
            <li key={note._id} className={note.minimized ? 'minimized' : ''}>
              {!note.minimized && (
                <textarea
                  value={note.content}
                  onChange={(e) => handleNoteChange(note._id, e.target.value)}
                  onBlur={(e) => handleUpdateNote(note._id, e.target.value)}
                  onInput={autoResizeTextarea}
                  onFocus={() => handleNoteFocus(note._id, noteTextareaRefs.current[note._id] || (noteTextareaRefs.current[note._id] = React.createRef()))}
                  ref={noteTextareaRefs.current[note._id] || (noteTextareaRefs.current[note._id] = React.createRef())}
                  rows={1}
                />
              )}
              <div className="note-controls">
                <button onClick={() => handleDeleteNote(note._id)}>
                  <i className="fas fa-trash"></i>
                </button>
                <button onClick={() => handleMinimizeNote(note._id)}>
                  <i className={`fas ${note.minimized ? 'fa-plus' : 'fa-minus'}`}></i>
                </button>
              </div>
            </li>
          ))}
        </ul>
        <textarea
          value={noteContent}
          onChange={(e) => setNoteContent(e.target.value)}
          onFocus={() => handleNoteFocus('new', noteTextareaRefs.current.new || (noteTextareaRefs.current.new = React.createRef()))}
          placeholder="Add a note..."
          onInput={autoResizeTextarea}
          ref={noteTextareaRefs.current.new || (noteTextareaRefs.current.new = React.createRef())}
          rows={1}
        ></textarea>
        <button onClick={handleAddNote}>Add Note</button>
      </div>
    </div>
  );
};

export default VideoMediaViewer;
