// Path: /src/pages/admin/AdminDashboard.js

import React from 'react';
import { Outlet } from 'react-router-dom';
import AdminNavigation from '../../components/AdminNavigation';
import './AdminDashboard.css';

const AdminDashboard = () => {
  return (
    <div className="admin-dashboard-container">
      <AdminNavigation />
      <div className="admin-dashboard-content">
        <Outlet />
      </div>
    </div>
  );
};

export default AdminDashboard;
