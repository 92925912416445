// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* path src.pages.admin.StudentLogs.css */

.student-logs-container {
  padding: 20px;
}

.student-logs-container h1 {
  font-size: 2em;
  margin-bottom: 20px;
}

.student-logs-container select {
  padding: 10px;
  margin-bottom: 20px;
}

.student-logs-container ul {
  list-style-type: none;
  padding: 0;
}

.student-logs-container li {
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/admin/StudentLogs.css"],"names":[],"mappings":"AAAA,yCAAyC;;AAEzC;EACE,aAAa;AACf;;AAEA;EACE,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;EACrB,UAAU;AACZ;;AAEA;EACE,mBAAmB;EACnB,aAAa;EACb,sBAAsB;EACtB,kBAAkB;AACpB","sourcesContent":["/* path src.pages.admin.StudentLogs.css */\r\n\r\n.student-logs-container {\r\n  padding: 20px;\r\n}\r\n\r\n.student-logs-container h1 {\r\n  font-size: 2em;\r\n  margin-bottom: 20px;\r\n}\r\n\r\n.student-logs-container select {\r\n  padding: 10px;\r\n  margin-bottom: 20px;\r\n}\r\n\r\n.student-logs-container ul {\r\n  list-style-type: none;\r\n  padding: 0;\r\n}\r\n\r\n.student-logs-container li {\r\n  margin-bottom: 10px;\r\n  padding: 10px;\r\n  border: 1px solid #ccc;\r\n  border-radius: 4px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
