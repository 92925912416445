// path / src/pages/admin/CreateLessonComponent.js

import React, { useState } from 'react';
import axios from '../../config/axios';
import notify from '../../components/CustomToast';
import './CreateLessonComponent.css';

const acceptedTypes = ['theory', 'practice', 'exercise', 'test', 'summary']; // Accepted types

const CreateLessonComponent = ({ onComponentCreated }) => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [uniqueId, setUniqueId] = useState('');
  const [type, setType] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!acceptedTypes.includes(type.toLowerCase())) {
      notify(`Invalid type. Accepted values are: ${acceptedTypes.join(', ')}`, 'error');
      return;
    }

    const formData = {
      title,
      description,
      uniqueId,
      type: type.toLowerCase(), // Ensure type is in lowercase
    };

    try {
      await axios.post('/components', formData);
      notify('Lesson component created successfully!', 'success');
      setTitle('');
      setDescription('');
      setUniqueId('');
      setType(''); // Reset type field

      if (typeof onComponentCreated === 'function') {
        onComponentCreated();
      }
    } catch (error) {
      console.error('Error creating lesson component', error);
      setError('Error creating lesson component');
      notify('Error creating lesson component', 'error');
    }
  };

  return (
    <div className="component-form">
      <h2>Create Lesson Component</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="title">Title</label>
          <input
            type="text"
            id="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="description">Description</label>
          <textarea
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          ></textarea>
        </div>
        <div className="form-group">
          <label htmlFor="uniqueId">Unique ID</label>
          <input
            type="text"
            id="uniqueId"
            value={uniqueId}
            onChange={(e) => setUniqueId(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="type">Type</label>
          <input
            type="text"
            id="type"
            value={type}
            onChange={(e) => setType(e.target.value)}
            required
          />
        </div>
        {error && <p className="error-message">{error}</p>}
        <button type="submit" className="submit-btn">Create</button>
      </form>
    </div>
  );
};

export default CreateLessonComponent;
