// path / src/pages/admin/ViewLearningPaths.js

import React, { useEffect, useState } from 'react';
import axios from '../../config/axios';
import './ViewLearningPaths.css';

const ViewLearningPaths = () => {
  const [learningPaths, setLearningPaths] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchLearningPaths = async () => {
      try {
        const response = await axios.get('/admin/learning-paths');
        setLearningPaths(response.data);
      } catch (error) {
        console.error('Error fetching learning paths:', error);
        setError('Error fetching learning paths');
      }
    };

    fetchLearningPaths();
  }, []);

  return (
    <div className="view-learning-paths">
      <h1>Learning Paths</h1>
      {error && <p>{error}</p>}
      <ul>
        {learningPaths.map((path) => (
          <li key={path._id}>
            <h2>{path.title}</h2>
            <p>{path.description}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ViewLearningPaths;
