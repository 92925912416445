// path / src/pages/admin/AddContentToLesson.js

import React, { useState, useEffect } from 'react';
import axios from '../../config/axios';
import './AddContentToLesson.css';

const AddContentToLesson = () => {
  const [lessons, setLessons] = useState([]);
  const [selectedLesson, setSelectedLesson] = useState('');
  const [components, setComponents] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchLessons = async () => {
      try {
        const response = await axios.get('/lessons');
        setLessons(response.data);
      } catch (error) {
        console.error('Error fetching lessons', error);
        setError('Error fetching lessons');
      }
    };
    fetchLessons();
  }, []);

  const handleAddComponent = () => {
    setComponents([...components, { type: '', media: [] }]);
  };

  const handleAddMedia = (index) => {
    const newComponents = [...components];
    newComponents[index].media.push({ type: '', data: '', index: newComponents[index].media.length + 1, addressingString: '' });
    setComponents(newComponents);
  };

  const handleFileUpload = async (file) => {
    try {
      const formData = new FormData();
      formData.append('media', file);
      const response = await axios.post('/media/upload', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      return response.data.url;
    } catch (error) {
      console.error('Error uploading file:', error);
      throw error;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      // Check if all media files have been uploaded
      for (let component of components) {
        for (let media of component.media) {
          if (!media.data) {
            throw new Error('Please upload all media files before submitting.');
          }
        }
      }

      await axios.put(`/lessons/${selectedLesson}`, { components });
      alert('Lesson content added successfully!');
      setComponents([]);
    } catch (error) {
      console.error('Error adding content to lesson', error.response ? error.response.data : error.message);
      setError(error.response ? error.response.data.message : error.message);
    }
  };

  const handleComponentTypeChange = (index, type) => {
    const newComponents = [...components];
    newComponents[index].type = type;
    setComponents(newComponents);
  };

  const handleMediaChange = (compIndex, mediaIndex, field, value) => {
    const newComponents = [...components];
    newComponents[compIndex].media[mediaIndex][field] = value;
    setComponents(newComponents);
  };

  const handleManageMediaData = async (compIndex, mediaIndex, file) => {
    try {
      const url = await handleFileUpload(file);
      handleMediaChange(compIndex, mediaIndex, 'data', url);
    } catch (error) {
      setError('Error uploading media file.');
    }
  };

  return (
    <div className="content-form">
      <h1>Add Content to Lesson</h1>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="lesson">Select Lesson</label>
          <select
            id="lesson"
            value={selectedLesson}
            onChange={(e) => setSelectedLesson(e.target.value)}
            required
          >
            <option value="" disabled>Select a lesson</option>
            {lessons.map((lesson) => (
              <option key={lesson._id} value={lesson._id}>{lesson.title}</option>
            ))}
          </select>
        </div>
        {components.map((component, compIndex) => (
          <div key={compIndex} className="component-group">
            <div className="form-group">
              <label>Component Type</label>
              <select
                value={component.type}
                onChange={(e) => handleComponentTypeChange(compIndex, e.target.value)}
                required
              >
                <option value="">Select type</option>
                <option value="theory">Theory</option>
                <option value="practice">Practice</option>
                <option value="exercise">Exercise</option>
                <option value="test">Test</option>
                <option value="summary">Summary</option>
              </select>
            </div>
            <button type="button" className="add-media-btn" onClick={() => handleAddMedia(compIndex)}>
              Add Media
            </button>
            {component.media.map((media, mediaIndex) => (
              <div key={mediaIndex} className="media-group">
                <div className="form-group">
                  <label>Media Type</label>
                  <select
                    value={media.type}
                    onChange={(e) => handleMediaChange(compIndex, mediaIndex, 'type', e.target.value)}
                    required
                  >
                    <option value="">Select type</option>
                    <option value="text">Text</option>
                    <option value="audio">Audio</option>
                    <option value="video">Video</option>
                    <option value="ppt">PPT</option>
                    <option value="animation">Animation</option>
                    <option value="youtube">YouTube</option>
                  </select>
                </div>
                <div className="form-group">
                  <label>Addressing String</label>
                  <input
                    type="text"
                    value={media.addressingString}
                    onChange={(e) => handleMediaChange(compIndex, mediaIndex, 'addressingString', e.target.value)}
                    required
                  />
                </div>
                <input
                  type="file"
                  className="file-input"
                  onChange={(e) => handleManageMediaData(compIndex, mediaIndex, e.target.files[0])}
                  required
                />
              </div>
            ))}
          </div>
        ))}
        <button type="button" className="add-component-btn" onClick={handleAddComponent}>
          Add Component
        </button>
        {error && <p className="error-message">{error}</p>}
        <button type="submit" className="submit-btn">Save</button>
      </form>
    </div>
  );
};

export default AddContentToLesson;
