// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* path src.pages.admin.StudentDetails.css */

.student-details {
  padding: 20px;
}

.student-details h1 {
  font-size: 2em;
  margin-bottom: 20px;
}

.student-details p {
  font-size: 1.2em;
}
`, "",{"version":3,"sources":["webpack://./src/pages/admin/StudentDetails.css"],"names":[],"mappings":"AAAA,4CAA4C;;AAE5C;EACE,aAAa;AACf;;AAEA;EACE,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":["/* path src.pages.admin.StudentDetails.css */\r\n\r\n.student-details {\r\n  padding: 20px;\r\n}\r\n\r\n.student-details h1 {\r\n  font-size: 2em;\r\n  margin-bottom: 20px;\r\n}\r\n\r\n.student-details p {\r\n  font-size: 1.2em;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
