// path / src/pages/admin/RemoveLearningPathAssignment.js

import React, { useState, useEffect } from 'react';
import axios from '../../config/axios';
import './RemoveLearningPathAssignment.css';

const RemoveLearningPathAssignment = () => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [message, setMessage] = useState('');

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get('/users/with-creator');
        console.log('Fetched users response:', response.data);
        setUsers(response.data);
      } catch (error) {
        console.error('Error fetching users:', error);
        setMessage('Error fetching users');
      }
    };

    fetchUsers();
  }, []);

  const handleSelectUser = (userId) => {
    const user = users.find((user) => user._id === userId);
    setSelectedUser(user);
  };

  const handleRemoveLearningPath = async (learningPathId) => {
    try {
      await axios.post('/users/remove-learning-path', {
        userId: selectedUser._id,
        learningPathId,
      });
      setSelectedUser({
        ...selectedUser,
        learningPaths: selectedUser.learningPaths.filter((path) => path._id !== learningPathId),
      });
      setMessage('Learning Path removed successfully!');
    } catch (error) {
      console.error('Error removing learning path:', error);
      setMessage('Error removing learning path');
    }
  };

  return (
    <div className="remove-learning-path-assignment-container">
      <h1>Remove Learning Path Assignment</h1>
      {message && <p className="message">{message}</p>}
      <div className="form-group">
        <label htmlFor="userSelect">Select User:</label>
        <select id="userSelect" onChange={(e) => handleSelectUser(e.target.value)}>
          <option value="">Select User</option>
          {users.map((user) => (
            <option key={user._id} value={user._id}>
              {user.username} ({user.role})
            </option>
          ))}
        </select>
      </div>
      {selectedUser && (
        <div className="assigned-learning-paths">
          <h2>Assigned Learning Paths for {selectedUser.username}</h2>
          <ul>
            {selectedUser.learningPaths.map((path) => (
              <li key={`${path._id}-${selectedUser._id}`}>
                {path.title}
                <button onClick={() => handleRemoveLearningPath(path._id)}>Remove</button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default RemoveLearningPathAssignment;
