// path / src/pages/admin/StudentLogs.js

import React, { useState, useEffect } from 'react';
import axios from '../../config/axios';
import { useParams } from 'react-router-dom';
import './StudentLogs.css';

const StudentLogs = () => {
  const { studentId } = useParams();
  const [students, setStudents] = useState([]);
  const [logs, setLogs] = useState([]);
  const [message, setMessage] = useState('');

  useEffect(() => {
    fetchStudents();
    if (studentId) {
      fetchLogs(studentId);
    }
  }, [studentId]);

  const fetchStudents = async () => {
    try {
      const response = await axios.get('/students');
      setStudents(response.data);
    } catch (error) {
      console.error('Error fetching students:', error);
      setMessage('Error fetching students');
    }
  };

  const fetchLogs = async (studentId) => {
    try {
      const response = await axios.get(`/student/logs/${studentId}`);
      setLogs(response.data);
    } catch (error) {
      console.error('Error fetching logs:', error);
      setMessage('Error fetching logs');
    }
  };

  const handleStudentChange = (e) => {
    const selectedStudentId = e.target.value;
    if (selectedStudentId) {
      fetchLogs(selectedStudentId);
    }
  };

  return (
    <div className="student-logs-container">
      <h1>Student Logs</h1>
      {message && <p>{message}</p>}
      <div>
        <label>Select Student:</label>
        <select onChange={handleStudentChange}>
          <option value="">Select Student</option>
          {students.map(student => (
            <option key={student._id} value={student._id}>
              {student.username}
            </option>
          ))}
        </select>
      </div>
      {logs.length > 0 && (
        <div>
          <h2>Logs</h2>
          <ul>
            {logs.map(log => (
              <li key={log._id}>{log.message} <span>{new Date(log.timestamp).toLocaleString()}</span></li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default StudentLogs;
