// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* path src.pages.admin.LessonDetails.css */

.lesson-details-container {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.left-section, .right-section {
  flex: 1 1;
  margin: 10px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #f9f9f9;
}

.left-section {
  max-width: 300px;
  overflow-y: auto;
}

.right-section {
  flex: 2 1;
}

.right-section ul {
  list-style-type: none;
  padding: 0;
}

.right-section ul ul {
  list-style-type: disc;
  padding-left: 20px;
}

.component-item {
  margin-bottom: 20px;
}

.media-item {
  margin-left: 20px;
}

h1, h2, h3 {
  margin: 0 0 10px;
}

p {
  margin: 5px 0;
}
`, "",{"version":3,"sources":["webpack://./src/pages/admin/LessonDetails.css"],"names":[],"mappings":"AAAA,2CAA2C;;AAE3C;EACE,aAAa;EACb,8BAA8B;EAC9B,aAAa;AACf;;AAEA;EACE,SAAO;EACP,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,yBAAyB;AAC3B;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,SAAO;AACT;;AAEA;EACE,qBAAqB;EACrB,UAAU;AACZ;;AAEA;EACE,qBAAqB;EACrB,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;AACf","sourcesContent":["/* path src.pages.admin.LessonDetails.css */\r\n\r\n.lesson-details-container {\r\n  display: flex;\r\n  justify-content: space-between;\r\n  padding: 20px;\r\n}\r\n\r\n.left-section, .right-section {\r\n  flex: 1;\r\n  margin: 10px;\r\n  padding: 10px;\r\n  border: 1px solid #ddd;\r\n  border-radius: 4px;\r\n  background-color: #f9f9f9;\r\n}\r\n\r\n.left-section {\r\n  max-width: 300px;\r\n  overflow-y: auto;\r\n}\r\n\r\n.right-section {\r\n  flex: 2;\r\n}\r\n\r\n.right-section ul {\r\n  list-style-type: none;\r\n  padding: 0;\r\n}\r\n\r\n.right-section ul ul {\r\n  list-style-type: disc;\r\n  padding-left: 20px;\r\n}\r\n\r\n.component-item {\r\n  margin-bottom: 20px;\r\n}\r\n\r\n.media-item {\r\n  margin-left: 20px;\r\n}\r\n\r\nh1, h2, h3 {\r\n  margin: 0 0 10px;\r\n}\r\n\r\np {\r\n  margin: 5px 0;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
