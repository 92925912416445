// path / src/pages/admin/EditLesson.js

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from '../../config/axios';
import notify from '../../components/CustomToast';
import './EditLesson.css';

const EditLesson = () => {
  const { lessonId } = useParams(); // Ensure the parameter name matches the route
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [uniqueId, setUniqueId] = useState('');
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchLesson = async () => {
      try {
        console.log(`Fetching lesson with ID: ${lessonId}`);
        const response = await axios.get(`/lessons/${lessonId}`);
        const lesson = response.data;
        console.log('Fetched lesson:', lesson);
        setTitle(lesson.title);
        setDescription(lesson.description);
        setUniqueId(lesson.uniqueId);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching lesson:', error);
        notify('Error fetching lesson data', 'error');
        setLoading(false);
      }
    };

    fetchLesson();
  }, [lessonId]); // Ensure the useEffect dependency is correct

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      console.log('Submitting updated lesson:', { title, description, uniqueId });
      await axios.put(`/lessons/${lessonId}`, { title, description, uniqueId });
      notify('Lesson updated successfully!', 'success');
      navigate('/admin-dashboard/manage-lessons');
    } catch (error) {
      console.error('Error updating lesson:', error);
      notify('Error updating lesson. Please try again.', 'error');
    }
  };

  const handleCancel = () => {
    navigate('/admin-dashboard/manage-lessons');
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="lesson-form">
      <h1>Edit Lesson</h1>
      <form onSubmit={handleSubmit}>
        <label>
          Title:
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
        </label>
        <br />
        <label>
          Description:
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          />
        </label>
        <br />
        <label>
          Lesson ID (Unique):
          <input
            type="text"
            value={uniqueId}
            onChange={(e) => setUniqueId(e.target.value)}
            required
          />
        </label>
        <br />
        <button type="submit">Update</button>
        <button type="button" onClick={handleCancel}>Cancel</button>
      </form>
    </div>
  );
};

export default EditLesson;
