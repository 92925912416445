// path/src/pages/admin/AssignLearningPath.js

import React, { useState, useEffect } from 'react';
import axios from '../../config/axios';
import './AssignLearningPath.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AssignLearningPath = () => {
  const [users, setUsers] = useState([]);
  const [learningPaths, setLearningPaths] = useState([]);
  const [selectedUser, setSelectedUser] = useState('');
  const [selectedLearningPath, setSelectedLearningPath] = useState('');
  const [userLearningPaths, setUserLearningPaths] = useState([]); // Add state for user learning paths

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [usersResponse, pathsResponse] = await Promise.all([
          axios.get('/users/learning-paths'),
          axios.get('/admin/learning-paths')
        ]);
        setUsers(usersResponse.data);
        setLearningPaths(pathsResponse.data);
      } catch (error) {
        console.error('Error fetching data:', error);
        toast.error('Error fetching data. Please try again.');
      }
    };

    fetchData();
  }, []);

  const handleUserChange = (event) => {
    const userId = event.target.value;
    setSelectedUser(userId);

    const user = users.find((u) => u._id === userId);
    setUserLearningPaths(user ? user.learningPaths : []); // Set the learning paths of the selected user
  };

  const handleAssign = async () => {
    if (!selectedUser || !selectedLearningPath) {
      toast.warning('Please select both a user and a learning path.');
      return;
    }

    if (userLearningPaths.find((path) => path._id === selectedLearningPath)) {
      toast.warning('This learning path is already assigned to the user.');
      return;
    }

    try {
      await axios.post('/users/assign-learning-path', {
        userId: selectedUser,
        learningPathId: selectedLearningPath
      });

      // Update user learning paths after assigning a new one
      const assignedPath = learningPaths.find((path) => path._id === selectedLearningPath);
      setUserLearningPaths((prevPaths) => [...prevPaths, assignedPath]);

      toast.success('Learning Path assigned successfully.');
    } catch (error) {
      console.error('Error assigning learning path:', error);
      toast.error('Error assigning learning path. Please try again.');
    }
  };

  return (
    <div className="assign-learning-path-container">
      <h1>Assign Learning Path</h1>
      <div className="form-group">
        <label htmlFor="user">Select User:</label>
        <select id="user" value={selectedUser} onChange={handleUserChange}>
          <option value="">Select a user</option>
          {users.map((user) => (
            <option key={user._id} value={user._id}>
              {user.username}
            </option>
          ))}
        </select>
      </div>
      <div className="form-group">
        <label htmlFor="learningPath">Select Learning Path:</label>
        <select
          id="learningPath"
          value={selectedLearningPath}
          onChange={(e) => setSelectedLearningPath(e.target.value)}
        >
          <option value="">Select a learning path</option>
          {learningPaths.map((path) => (
            <option key={path._id} value={path._id}>
              {path.title}
            </option>
          ))}
        </select>
      </div>
      <button onClick={handleAssign}>Assign Learning Path</button>
      {userLearningPaths.length > 0 && (
        <div className="assigned-learning-paths">
          <h2>Assigned Learning Paths:</h2>
          <ul>
            {userLearningPaths.map((path) => (
              <li key={path._id}>{path.title}</li>
            ))}
          </ul>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default AssignLearningPath;
