// Path: /src/pages/admin/CompressVideoPage.js

import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import axios from '../../config/axios';
import notify from '../../components/CustomToast';
import './CompressVideoPage.css';

const CompressVideoPage = () => {
  const [files, setFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [compressionSpeed, setCompressionSpeed] = useState('medium');
  const [isCompressing, setIsCompressing] = useState(false);

  const fetchFiles = useCallback(async () => {
    try {
      const response = await axios.get('/media/list-files');
      const filesData = response.data
        .filter(file => file.name.endsWith('.mp4'))
        .map(file => ({
          name: file.name,
          size: formatFileSize(file.size),
          lastModified: formatDate(file.lastModified),
        }));
      setFiles(filesData);
    } catch (error) {
      console.error('Error fetching files:', error);
    }
  }, []);

  useEffect(() => {
    fetchFiles();
  }, [fetchFiles]);

  const formatFileSize = (size) => {
    const i = Math.floor(Math.log(size) / Math.log(1024));
    return `${(size / Math.pow(1024, i)).toFixed(2)} ${['B', 'KB', 'MB', 'GB', 'TB'][i]}`;
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return isNaN(date.getTime()) ? 'Invalid Date' : date.toLocaleDateString();
  };

  const handleFileSelect = (e) => {
    setSelectedFile(e.target.value);
  };

  const handleCompress = async () => {
    if (!selectedFile) {
      notify('Please select a file before compressing.', 'error');
      return;
    }

    setIsCompressing(true);

    try {
      const formData = {
        fileName: selectedFile,
        compressionSpeed: compressionSpeed,
      };

      console.log('Sending data:', formData);

      const response = await axios.post('/media/compress-video', formData);
      notify('Video compressed successfully', 'success');
      console.log('Compression response:', response.data);

      fetchFiles();
      setSelectedFile(null);
    } catch (error) {
      console.error('Error compressing video:', error);
      notify('Error compressing video', 'error');
    } finally {
      setIsCompressing(false);
    }
  };

  const handleDelete = async (fileName) => {
    try {
      console.log(`Attempting to delete file: ${fileName}`);
      await axios.delete(`/media/uploads/${fileName}`);
      notify('File deleted successfully', 'success');
      
      fetchFiles();
      setSelectedFile(null);
    } catch (error) {
      console.error('Error deleting file:', error);
      notify('Error deleting file', 'error');
    }
  };

  return (
    <div className="compress-video-page">
      <Link to="/admin-dashboard" className="back-link">
        &larr; Back to Dashboard
      </Link>
      <h2>Compress Video Files</h2>
      <div className="form-group">
        <label htmlFor="file-select">Select Video File</label>
        <div className="file-select-wrapper">
          <select id="file-select" onChange={handleFileSelect}>
            <option value="">Choose a file</option>
            {files.map((file, index) => (
              <option key={index} value={file.name}>
                {file.name}
              </option>
            ))}
          </select>
          {selectedFile && (
            <div className="file-info">
              <span className="file-size">{files.find(f => f.name === selectedFile)?.size}</span>
              <span className="file-date">{files.find(f => f.name === selectedFile)?.lastModified}</span>
              <button onClick={() => handleDelete(selectedFile)} className="delete-button">
                Delete File
              </button>
            </div>
          )}
        </div>
      </div>
      <div className="form-group">
        <label htmlFor="compression-speed">Select Compression Speed</label>
        <select id="compression-speed" value={compressionSpeed} onChange={(e) => setCompressionSpeed(e.target.value)}>
          <option value="ultrafast">Ultra Fast</option>
          <option value="superfast">Super Fast</option>
          <option value="veryfast">Very Fast</option>
          <option value="faster">Faster</option>
          <option value="fast">Fast</option>
          <option value="medium">Medium</option>
          <option value="slow">Slow</option>
          <option value="slower">Slower</option>
          <option value="veryslow">Very Slow</option>
        </select>
      </div>
      <button onClick={handleCompress} disabled={isCompressing}>
        {isCompressing ? 'Compressing...' : 'Compress Video'}
      </button>
    </div>
  );
};

export default CompressVideoPage;
