// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* path src.pages.admin.ViewProgress.css */

.view-progress {
  padding: 20px;
}

.view-progress h1 {
  font-size: 2em;
  margin-bottom: 20px;
}

.view-progress ul {
  list-style-type: none;
  padding: 0;
}

.view-progress li {
  background: #f9f9f9;
  border: 1px solid #ddd;
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 5px;
}

.view-progress h2 {
  margin: 0 0 10px 0;
}

.view-progress p {
  margin: 0;
}
`, "",{"version":3,"sources":["webpack://./src/pages/admin/ViewProgress.css"],"names":[],"mappings":"AAAA,0CAA0C;;AAE1C;EACE,aAAa;AACf;;AAEA;EACE,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;EACrB,UAAU;AACZ;;AAEA;EACE,mBAAmB;EACnB,sBAAsB;EACtB,aAAa;EACb,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,SAAS;AACX","sourcesContent":["/* path src.pages.admin.ViewProgress.css */\r\n\r\n.view-progress {\r\n  padding: 20px;\r\n}\r\n\r\n.view-progress h1 {\r\n  font-size: 2em;\r\n  margin-bottom: 20px;\r\n}\r\n\r\n.view-progress ul {\r\n  list-style-type: none;\r\n  padding: 0;\r\n}\r\n\r\n.view-progress li {\r\n  background: #f9f9f9;\r\n  border: 1px solid #ddd;\r\n  padding: 15px;\r\n  margin-bottom: 10px;\r\n  border-radius: 5px;\r\n}\r\n\r\n.view-progress h2 {\r\n  margin: 0 0 10px 0;\r\n}\r\n\r\n.view-progress p {\r\n  margin: 0;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
