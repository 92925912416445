// path / src/pages/admin/ViewMedia.js

import React, { useEffect, useState } from 'react';
import axios from '../../config/axios';
import MediaViewer from '../../components/MediaViewer/MediaViewer';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './ViewMedia.css';

const ViewMedia = () => {
  const [mediaList, setMediaList] = useState([]);
  const [error, setError] = useState('');
  const [selectedMedia, setSelectedMedia] = useState(null);

  useEffect(() => {
    const fetchMedia = async () => {
      try {
        const response = await axios.get('/media');
        setMediaList(response.data);
      } catch (error) {
        console.error('Error fetching media', error);
        setError('Error fetching media');
        toast.error('Error fetching media');
      }
    };

    fetchMedia();
  }, []);

  const handleViewDetails = (media) => {
    toast.info(`Title: ${media.title}\nDescription: ${media.description}`);
  };

  const handleOpen = (media) => {
    setSelectedMedia(media);
  };

  const handleClose = () => {
    setSelectedMedia(null);
  };

  return (
    <div className="view-media-container">
      <h1>View Media</h1>
      {error && <p className="error-message">{error}</p>}
      <ul className="media-list">
        {mediaList.map((media) => (
          <li key={media._id} className="media-item">
            <div className="media-info">
              <h3>{media.title}</h3>
              <p>{media.description}</p>
              <button onClick={() => handleViewDetails(media)}>View Details</button>
              <button onClick={() => handleOpen(media)}>Open</button>
            </div>
          </li>
        ))}
      </ul>
      {selectedMedia && (
        <div className="media-viewer-container">
          <MediaViewer media={selectedMedia} onClose={handleClose} />
        </div>
      )}
    </div>
  );
};

export default ViewMedia;
