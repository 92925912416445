// Path: /src/context/AuthContext.js

import React, { createContext, useState, useEffect, useContext } from 'react';
import axios from '../config/axios';
import {jwtDecode} from 'jwt-decode';  // Ensure jwt-decode is installed

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userRole, setUserRole] = useState(null);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem('token');
    
    // Check if token exists and is not expired
    if (token) {
      const decodedToken = jwtDecode(token);
      if (Date.now() >= decodedToken.exp * 1000) {
        localStorage.removeItem('token');
        setLoading(false);
      } else {
        fetchUser(token);
      }
    } else {
      setLoading(false);
    }

    // Cleanup before page unload - consider removing this if you want tokens to persist across reloads
    const handleBeforeUnload = () => {
      localStorage.clear();
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  // Fetch authenticated user details
  const fetchUser = async (token) => {
    try {
      const response = await axios.get('/auth/me', {
        headers: { Authorization: `Bearer ${token}` }
      });
      setUser(response.data);
      setIsAuthenticated(true);
      setUserRole(response.data.role);
    } catch (error) {
      console.error('Error fetching user:', error);
      setIsAuthenticated(false);
      localStorage.removeItem('token');  // Clear token if fetchUser fails
    } finally {
      setLoading(false);
    }
  };

  // Login function
  const login = async (credentials) => {
    try {
      const response = await axios.post('/auth/login', credentials);
      const { token } = response.data;
      localStorage.setItem('token', token);

      const decodedToken = jwtDecode(token);  // Decode the token
      setUser(decodedToken.user);
      setIsAuthenticated(true);
      setUserRole(decodedToken.user.role);
      await fetchUser(token); // Fetch user data immediately after setting token
      return response;
    } catch (error) {
      setIsAuthenticated(false);
      throw error;
    }
  };

  // Logout function
  const logout = () => {
    localStorage.removeItem('token');  // Clear token on logout
    setUser(null);
    setIsAuthenticated(false);
    setUserRole(null);
    setLoading(false);
    window.location.href = '/login'; // Force redirect to login page
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, userRole, user, loading, login, logout }}>
      {!loading && children}  {/* Only render children after loading completes */}
    </AuthContext.Provider>
  );
};

// Hook for consuming the AuthContext
export const useAuth = () => useContext(AuthContext);
