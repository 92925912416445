// path / src/pages/admin/ManageMedia.js

import React, { useEffect, useState } from 'react';
import axios from '../../config/axios';
import { Link } from 'react-router-dom';
import './ManageMedia.css';

const ManageMedia = () => {
  const [mediaList, setMediaList] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchMedia = async () => {
      try {
        const response = await axios.get('/media');
        console.log('Media fetched:', response.data);
        setMediaList(response.data);
      } catch (error) {
        console.error('Error fetching media', error);
        setError('Error fetching media');
      }
    };

    fetchMedia();
  }, []);

  const handleDelete = async (mediaId) => {
    try {
      await axios.delete(`/media/${mediaId}`);
      setMediaList(mediaList.filter((media) => media._id !== mediaId));
    } catch (error) {
      console.error('Error deleting media', error);
      setError('Error deleting media');
    }
  };

  return (
    <div className="manage-media-container">
      <h1>Manage Media</h1>
      {error && <p className="error-message">{error}</p>}
      <ul className="media-list">
        {mediaList.map((media) => (
          <li key={media._id} className="media-item">
            <span>{media.title}</span>
            <Link to={`/admin-dashboard/edit-media/${media._id}`}>Edit</Link>
            <button onClick={() => handleDelete(media._id)}>Delete</button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ManageMedia;
