// path / src/pages/admin/CreateUser.js

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import axios from '../../config/axios';
import './CreateUser.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../../hooks/useAuth';

function CreateUser() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [role, setRole] = useState('student');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [message, setMessage] = useState('');
  const { token } = useAuth();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!username || !password) {
      setMessage('Username and password cannot be empty');
      return;
    }

    try {
      await axios.post(
        '/users',
        { username, password, role },
        {
          headers: {
            Authorization: `Bearer ${token}`, // Add authorization header with token
          },
        }
      );
      setMessage('User created successfully');
      setUsername('');
      setPassword('');
      setRole('student');
      setPasswordVisible(false);
    } catch (error) {
      console.error('Error creating user:', error);
      if (error.response && error.response.status === 403) {
        setMessage('You do not have permission to create a user.');
      } else {
        setMessage('Error creating user');
      }
    }
  };

  return (
    <div className="create-user-container">
      <h1 className="create-user-header">Create User</h1>
      <form onSubmit={handleSubmit} className="create-user-form">
        <label>
          Username:
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </label>
        <label>
          Password:
          <div className="password-field">
            <input
              type={passwordVisible ? 'text' : 'password'}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <FontAwesomeIcon
              icon={passwordVisible ? faEyeSlash : faEye}
              onClick={() => setPasswordVisible(!passwordVisible)}
              style={{ cursor: 'pointer', marginLeft: '10px' }}
            />
          </div>
        </label>
        <label>
          Role:
          <select value={role} onChange={(e) => setRole(e.target.value)}>
            <option value="student">Student</option>
            <option value="teacherAdm">Teacher (Admin)</option>
            <option value="teacherNoAdm">Teacher (Non-Admin)</option>
            <option value="admin">Admin</option>
          </select>
        </label>
        <button type="submit">Create User</button>
        {message && <p>{message}</p>}
      </form>
      <Link to="/admin-dashboard" className="back-to-dashboard-link">Back to Dashboard</Link>
    </div>
  );
}

export default CreateUser;
