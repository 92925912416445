// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* path src.pages.admin.ViewLessons.css */

.view-lessons-container {
  display: flex;
  height: 100vh; /* Make the container take full height of the viewport */
}

.lessons-sidebar {
  width: 25%;
  padding: 20px;
  border-right: 1px solid #ccc;
  background-color: #f8f9fa;
  overflow-y: auto; /* Add scrollbar when content overflows */
}

.lessons-list {
  padding: 10px 0;
}

.lesson-item {
  cursor: pointer;
  padding: 10px;
  border: 1px solid #ccc;
  margin-bottom: 10px;
  background-color: #fff;
  transition: background-color 0.3s;
}

.lesson-item:hover {
  background-color: #e9ecef;
}

.lesson-details {
  width: 75%;
  padding: 20px;
  overflow-y: auto; /* Add scrollbar when content overflows */
}

.lesson-top-section,
.lesson-middle-section,
.lesson-bottom-section {
  margin-bottom: 20px;
}

.lesson-details h2,
.lesson-details p,
.lesson-details h3,
.lesson-details h4 {
  margin: 10px 0;
}
`, "",{"version":3,"sources":["webpack://./src/pages/admin/ViewLessons.css"],"names":[],"mappings":"AAAA,yCAAyC;;AAEzC;EACE,aAAa;EACb,aAAa,EAAE,wDAAwD;AACzE;;AAEA;EACE,UAAU;EACV,aAAa;EACb,4BAA4B;EAC5B,yBAAyB;EACzB,gBAAgB,EAAE,yCAAyC;AAC7D;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,sBAAsB;EACtB,iCAAiC;AACnC;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,UAAU;EACV,aAAa;EACb,gBAAgB,EAAE,yCAAyC;AAC7D;;AAEA;;;EAGE,mBAAmB;AACrB;;AAEA;;;;EAIE,cAAc;AAChB","sourcesContent":["/* path src.pages.admin.ViewLessons.css */\r\n\r\n.view-lessons-container {\r\n  display: flex;\r\n  height: 100vh; /* Make the container take full height of the viewport */\r\n}\r\n\r\n.lessons-sidebar {\r\n  width: 25%;\r\n  padding: 20px;\r\n  border-right: 1px solid #ccc;\r\n  background-color: #f8f9fa;\r\n  overflow-y: auto; /* Add scrollbar when content overflows */\r\n}\r\n\r\n.lessons-list {\r\n  padding: 10px 0;\r\n}\r\n\r\n.lesson-item {\r\n  cursor: pointer;\r\n  padding: 10px;\r\n  border: 1px solid #ccc;\r\n  margin-bottom: 10px;\r\n  background-color: #fff;\r\n  transition: background-color 0.3s;\r\n}\r\n\r\n.lesson-item:hover {\r\n  background-color: #e9ecef;\r\n}\r\n\r\n.lesson-details {\r\n  width: 75%;\r\n  padding: 20px;\r\n  overflow-y: auto; /* Add scrollbar when content overflows */\r\n}\r\n\r\n.lesson-top-section,\r\n.lesson-middle-section,\r\n.lesson-bottom-section {\r\n  margin-bottom: 20px;\r\n}\r\n\r\n.lesson-details h2,\r\n.lesson-details p,\r\n.lesson-details h3,\r\n.lesson-details h4 {\r\n  margin: 10px 0;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
