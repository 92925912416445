// Path: /src/hooks/useAuth.js

import { useState, useEffect, useCallback } from 'react';
import { jwtDecode } from 'jwt-decode'; // Ensure this import is correct
import axios from '../config/axios';

export const useAuth = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userRole, setUserRole] = useState(null);
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState(null);

  // Sync token from session storage
  const syncTokenFromStorage = useCallback(() => {
    const storedToken = sessionStorage.getItem('token');
    if (storedToken) {
      try {
        const decodedToken = jwtDecode(storedToken);
        const { exp, user } = decodedToken;
        if (Date.now() >= exp * 1000) {
          sessionStorage.removeItem('token');
          setIsAuthenticated(false);
          setUserRole(null);
          setToken(null);
        } else {
          setIsAuthenticated(true);
          setUserRole(user.role);
          setToken(storedToken);
        }
      } catch (error) {
        console.error('Error decoding token:', error);
        setIsAuthenticated(false);
        setUserRole(null);
        setToken(null);
      }
    } else {
      setIsAuthenticated(false);
      setUserRole(null);
      setToken(null);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    syncTokenFromStorage();
  }, [syncTokenFromStorage]);

  const login = async (credentials) => {
    try {
      const response = await axios.post('/auth/login', credentials);
      const { token } = response.data;
      sessionStorage.setItem('token', token);
      syncTokenFromStorage();
    } catch (error) {
      setIsAuthenticated(false);
      setUserRole(null);
      setToken(null);
      throw error;
    }
  };

  const logout = () => {
    sessionStorage.removeItem('token');
    setIsAuthenticated(false);
    setUserRole(null);
    setToken(null);
  };

  return {
    isAuthenticated,
    userRole,
    login,
    logout,
    loading,
    token,
  };
};
