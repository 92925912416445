// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* path src.pages.admin.ViewLearningPaths.css */

.view-learning-paths {
  padding: 20px;
}

.view-learning-paths h1 {
  font-size: 2em;
  margin-bottom: 20px;
}

.view-learning-paths ul {
  list-style-type: none;
  padding: 0;
}

.view-learning-paths li {
  background: #f9f9f9;
  border: 1px solid #ddd;
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 5px;
}

.view-learning-paths h2 {
  margin: 0 0 10px 0;
}

.view-learning-paths p {
  margin: 0;
}
`, "",{"version":3,"sources":["webpack://./src/pages/admin/ViewLearningPaths.css"],"names":[],"mappings":"AAAA,+CAA+C;;AAE/C;EACE,aAAa;AACf;;AAEA;EACE,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;EACrB,UAAU;AACZ;;AAEA;EACE,mBAAmB;EACnB,sBAAsB;EACtB,aAAa;EACb,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,SAAS;AACX","sourcesContent":["/* path src.pages.admin.ViewLearningPaths.css */\r\n\r\n.view-learning-paths {\r\n  padding: 20px;\r\n}\r\n\r\n.view-learning-paths h1 {\r\n  font-size: 2em;\r\n  margin-bottom: 20px;\r\n}\r\n\r\n.view-learning-paths ul {\r\n  list-style-type: none;\r\n  padding: 0;\r\n}\r\n\r\n.view-learning-paths li {\r\n  background: #f9f9f9;\r\n  border: 1px solid #ddd;\r\n  padding: 15px;\r\n  margin-bottom: 10px;\r\n  border-radius: 5px;\r\n}\r\n\r\n.view-learning-paths h2 {\r\n  margin: 0 0 10px 0;\r\n}\r\n\r\n.view-learning-paths p {\r\n  margin: 0;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
