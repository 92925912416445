// path / src/pages/admin/ViewLessons.js

import React, { useState, useEffect } from 'react';
import axios from '../../config/axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './ViewLessons.css';

function ViewLessons() {
  const [lessons, setLessons] = useState([]);
  const [selectedLesson, setSelectedLesson] = useState(null);

  useEffect(() => {
    fetchLessons();
  }, []);

  const fetchLessons = async () => {
    try {
      const response = await axios.get('/lessons/with-details');
      console.log('Fetched lessons response:', response.data);
      if (Array.isArray(response.data)) {
        setLessons(response.data);
      } else {
        throw new Error('Fetched data is not an array');
      }
    } catch (error) {
      console.error('Error fetching lessons:', error);
      toast.error('Error fetching lessons. Please try again.');
    }
  };

  const handleLessonClick = async (lessonId) => {
    try {
      const response = await axios.get(`/lessons/${lessonId}/with-details`);
      setSelectedLesson(response.data);
    } catch (error) {
      console.error('Error fetching lesson details:', error);
      toast.error('Error fetching lesson details. Please try again.');
    }
  };

  return (
    <div className="view-lessons-container">
      <div className="lessons-sidebar">
        <h1 className="view-lessons-header">Lessons</h1>
        <div className="lessons-list">
          {lessons && lessons.length > 0 ? (
            lessons.map((lesson) => (
              <div key={lesson._id} className="lesson-item" onClick={() => handleLessonClick(lesson._id)}>
                <h3>{lesson.title}</h3>
                <p><strong>Description:</strong> {lesson.description}</p>
                <p><strong>ID:</strong> {lesson.uniqueId}</p>
              </div>
            ))
          ) : (
            <p>No lessons available.</p>
          )}
        </div>
      </div>
      <div className="lesson-details">
        {selectedLesson ? (
          <>
            <div className="lesson-top-section">
              <h2>{selectedLesson.title}</h2>
              <p><strong>Description:</strong> {selectedLesson.description}</p>
              <p><strong>ID:</strong> {selectedLesson.uniqueId}</p>
            </div>
            <div className="lesson-middle-section">
              <h3>Learning Paths</h3>
              {selectedLesson.learningPaths && selectedLesson.learningPaths.length > 0 ? (
                <ul>
                  {selectedLesson.learningPaths.map((lp) => (
                    <li key={lp._id}>{lp.title}</li>
                  ))}
                </ul>
              ) : (
                <p>No learning paths linked to this lesson.</p>
              )}
            </div>
            <div className="lesson-bottom-section">
              <h3>Lesson Components</h3>
              {selectedLesson.components && selectedLesson.components.length > 0 ? (
                <ul>
                  {selectedLesson.components.map((component, index) => (
                    <li key={index}>
                      <h4>{component.type}</h4>
                      {component.media && component.media.length > 0 ? (
                        <ul>
                          {component.media.map((media, mediaIndex) => (
                            <li key={mediaIndex}>
                              <p>{media.title}</p>
                              <p>{media.type}</p>
                              <p>{media.description}</p>
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <p>No media linked to this component.</p>
                      )}
                    </li>
                  ))}
                </ul>
              ) : (
                <p>No components found for this lesson.</p>
              )}
            </div>
          </>
        ) : (
          <p>Select a lesson to see details</p>
        )}
      </div>
      <ToastContainer />
    </div>
  );
}

export default ViewLessons;
