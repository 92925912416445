// path / src/pages/admin/EditLessonComponent.js

import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import axios from '../../config/axios';
import './EditLessonComponent.css';
import notify from '../../components/CustomToast'; // Import the notify function

const EditLessonComponent = ({ componentId, onClose }) => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [uniqueId, setUniqueId] = useState('');
  const [type, setType] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchComponent = async () => {
      try {
        const response = await axios.get(`/components/${componentId}`);
        const { title, description, uniqueId, type } = response.data;
        setTitle(title);
        setDescription(description);
        setUniqueId(uniqueId);
        setType(type);
      } catch (error) {
        console.error('Error fetching component data', error);
        setError('Error fetching component data');
      }
    };

    fetchComponent();
  }, [componentId]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await axios.put(`/components/${componentId}`, { title, description, uniqueId, type });
      notify('Lesson component updated successfully!', 'success');
      onClose();
    } catch (error) {
      console.error('Error updating lesson component', error.response ? error.response.data : error.message);
      setError(error.response ? error.response.data.message : error.message);
      notify(`Error updating lesson component: ${error.response ? error.response.data.message : error.message}`, 'error');
    }
  };

  return (
    <Modal
      isOpen={!!componentId}
      onRequestClose={onClose}
      contentLabel="Edit Lesson Component"
      className="component-form-modal"
      overlayClassName="component-form-overlay"
    >
      <div className="component-form">
        <h2>Edit Lesson Component</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="title">Title</label>
            <input type="text" id="title" value={title} onChange={(e) => setTitle(e.target.value)} required />
          </div>
          <div className="form-group">
            <label htmlFor="description">Description</label>
            <textarea id="description" value={description} onChange={(e) => setDescription(e.target.value)} required></textarea>
          </div>
          <div className="form-group">
            <label htmlFor="uniqueId">Unique ID</label>
            <input type="text" id="uniqueId" value={uniqueId} onChange={(e) => setUniqueId(e.target.value)} required />
          </div>
          <div className="form-group">
            <label htmlFor="type">Type</label>
            <input type="text" id="type" value={type} onChange={(e) => setType(e.target.value)} required />
          </div>
          {error && <p className="error-message">{error}</p>}
          <div className="button-group">
            <button type="submit" className="submit-btn">Update</button>
            <button type="button" className="cancel-btn" onClick={onClose}>Cancel</button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default EditLessonComponent;
