// path / src/hooks/useScrollToTop.js

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, document.body.scrollHeight);
  }, [pathname]);
};

export default useScrollToTop;
