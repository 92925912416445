// Path: /src/pages/ViewMedia.js

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import VideoMediaViewer from '../components/MediaViewer/VideoMediaViewer';
import TextMediaViewer from '../components/MediaViewer/TextMediaViewer';

const ViewMedia = () => {
  const { id } = useParams(); // Get media ID from URL parameters
  const [media, setMedia] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    console.log(`ViewMedia: useEffect triggered with ID: ${id}`);

    const fetchMedia = async () => {
      try {
        console.log(`ViewMedia: Fetching media with ID: ${id}`);
        const response = await axios.get(`/media/${id}`);
        
        console.log('ViewMedia: Fetched media data:', response.data);
        setMedia(response.data);
      } catch (error) {
        console.error('ViewMedia: Error fetching media:', error);
        setError('Error fetching media data');
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchMedia();
    } else {
      console.error("ViewMedia: No ID provided in URL");
      setLoading(false);
      setError('Invalid media ID');
    }
  }, [id]);

  const handleClose = () => {
    console.log("ViewMedia: handleClose called");
    // Implement the logic to close the viewer and navigate back or close the modal.
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!media) {
    console.log("ViewMedia: Media is null after loading, displaying error message");
    return <div>Media not found</div>;
  }

  console.log("ViewMedia: Rendering media of type", media.type);

  switch (media.type) {
    case 'video':
      console.log("ViewMedia: Rendering VideoMediaViewer");
      return <VideoMediaViewer media={media} onClose={handleClose} />;
    case 'text':
      console.log("ViewMedia: Rendering TextMediaViewer");
      return <TextMediaViewer media={media} onClose={handleClose} />;
    default:
      console.log("ViewMedia: Unsupported media type", media.type);
      return <div>Unsupported media type</div>;
  }
};

export default ViewMedia;
