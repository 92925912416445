// path / src/pages/admin/LessonDetails.js

import React from 'react';
import { ToastContainer } from 'react-toastify'; // Keep only ToastContainer since it's used
import 'react-toastify/dist/ReactToastify.css';
import './LessonDetails.css';

const LessonDetails = ({ lesson }) => {
  if (!lesson) {
    return <div>Lesson not found</div>;
  }

  return (
    <div className="lesson-details-container">
      <div className="left-section">
        <h1>{lesson.title}</h1>
        <p><strong>Description:</strong> {lesson.description}</p>
        <p><strong>ID:</strong> {lesson.uniqueId}</p>
      </div>
      <div className="right-section">
        <h2>Lesson Components</h2>
        {lesson.components && lesson.components.length > 0 ? (
          <ul>
            {lesson.components.map((component, index) => (
              <li key={index} className="component-item">
                <p><strong>Title:</strong> {component.title} , <strong>Type:</strong> {component.type.charAt(0).toUpperCase() + component.type.slice(1)}</p>
                <p><strong>Description:</strong> {component.description}</p>
                {component.media && component.media.length > 0 ? (
                  <ul>
                    {component.media.map((media, mediaIndex) => (
                      <li key={mediaIndex} className="media-item">
                        <p><strong>Media Title:</strong> {media.title} , <strong>Media Type:</strong> {media.type}</p>
                        <p><strong>Description:</strong> {media.description}</p>
                        {media.url && (
                          <p>
                            <strong>Media URL or file link:</strong> <a href={media.url} target="_blank" rel="noopener noreferrer">{media.url}</a>
                          </p>
                        )}
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p>No media linked to this component.</p>
                )}
              </li>
            ))}
          </ul>
        ) : (
          <p>No components found for this lesson.</p>
        )}
      </div>
      <ToastContainer />
    </div>
  );
};

export default LessonDetails;
