// Path: /src/pages/NotReadyYet.js

import React from 'react';

const NotReadyYet = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <h1>This dashboard is not ready yet. Please check back later!</h1>
    </div>
  );
};

export default NotReadyYet;
