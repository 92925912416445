// path/src/routes/StudentRoutes.js

import React, { Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import StudentDashboard from '../pages/student/StudentDashboard';

const MyLearningPaths = React.lazy(() => import('../pages/student/MyLearningPaths'));
const MyProgress = React.lazy(() => import('../pages/student/MyProgress'));
const MyProfile = React.lazy(() => import('../pages/student/MyProfile'));
const ViewMyLearningPath = React.lazy(() => import('../pages/student/ViewMyLearningPath'));
const AttendMyLearningPath = React.lazy(() => import('../pages/student/AttendMyLearningPath'));

const StudentRoutes = () => (
  <Suspense fallback={<div>Loading...</div>}>
    <Routes>
      <Route path="/" element={<StudentDashboard />} />
      <Route path="my-learning-paths" element={<MyLearningPaths />} />
      <Route path="my-progress" element={<MyProgress />} />
      <Route path="my-profile" element={<MyProfile />} />
      <Route path="my-learning-paths/:id/view" element={<ViewMyLearningPath />} />
      <Route path="my-learning-paths/:id/attend" element={<AttendMyLearningPath />} />
    </Routes>
  </Suspense>
);

export default StudentRoutes;
