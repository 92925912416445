// path / src/pages/admin/ManageComponents.js

import React, { useState, useEffect } from 'react';
import axios from '../../config/axios';
import EditLessonComponent from './EditLessonComponent';
import './ManageComponents.css';
import { toast } from 'react-toastify';

const ManageComponents = () => {
  const [components, setComponents] = useState([]);
  const [mediaList, setMediaList] = useState([]);
  const [selectedComponent, setSelectedComponent] = useState(null);
  const [selectedMedia, setSelectedMedia] = useState('');
  const [error, setError] = useState('');
  const [editModalOpen, setEditModalOpen] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [componentsResponse, mediaResponse] = await Promise.all([
          axios.get('/components'),
          axios.get('/media')
        ]);

        setComponents(componentsResponse.data);
        setMediaList(mediaResponse.data);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Error fetching data');
        toast.error('Error fetching data');
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const verifyMediaFiles = async () => {
      if (!selectedComponent) return;

      const verifiedMedia = [];
      for (const mediaId of selectedComponent.media) {
        try {
          const response = await axios.get(`/uploads/check-file/${mediaId}`);
          if (response.status === 200) {
            verifiedMedia.push(mediaId);
          }
        } catch (error) {
          console.error(`Media file not found: ${mediaId}`);
          try {
            await axios.post(`/components/${selectedComponent._id}/remove-media`, { mediaId });
          } catch (removeError) {
            console.error(`Error removing media from component: ${removeError}`);
          }
        }
      }

      // Only update the state if the verified media list is different
      if (JSON.stringify(selectedComponent.media) !== JSON.stringify(verifiedMedia)) {
        setSelectedComponent({ ...selectedComponent, media: verifiedMedia });
      }
    };

    verifyMediaFiles();
  }, [selectedComponent]);

  const handleSelectComponent = (event) => {
    const componentId = event.target.value;
    const component = components.find((c) => c._id === componentId);
    setSelectedComponent(component);
  };

  const handleViewDetails = () => {
    toast.info(`Title: ${selectedComponent.title}\nDescription: ${selectedComponent.description}\nUnique ID: ${selectedComponent.uniqueId}`);
  };

  const handleEdit = () => {
    setEditModalOpen(true);
  };

  const handleDelete = async () => {
    if (window.confirm('Are you sure you want to delete this component?')) {
      try {
        await axios.delete(`/components/${selectedComponent._id}`);
        setComponents(components.filter(component => component._id !== selectedComponent._id));
        setSelectedComponent(null);
        toast.success('Component deleted successfully!');
      } catch (error) {
        console.error('Error deleting component:', error);
        setError('Error deleting component');
        toast.error('Error deleting component');
      }
    }
  };

  const handleAddMedia = async () => {
    if (!selectedMedia) {
      toast.warn('You must select a Media first');
      return;
    }

    if (!selectedComponent) {
      toast.warn('You must select a Component first');
      return;
    }

    if (selectedComponent.media.includes(selectedMedia)) {
      toast.warn('This media is already added to the selected component');
      return;
    }

    try {
      await axios.post(`/components/${selectedComponent._id}/add-media`, { media: [{ _id: selectedMedia }] });
      setSelectedComponent({ ...selectedComponent, media: [...selectedComponent.media, selectedMedia] });
      toast.success('Media added to component successfully!');
    } catch (error) {
      console.error('Error adding media to component:', error);
      setError('Error adding media to component');
      toast.error('Error adding media to component');
    }
  };

  const handleRemoveMedia = async (mediaId) => {
    try {
      await axios.post(`/components/${selectedComponent._id}/remove-media`, { mediaId });
      setSelectedComponent({ ...selectedComponent, media: selectedComponent.media.filter(m => m !== mediaId) });
      toast.success('Media removed from component successfully!');
    } catch (error) {
      console.error('Error removing media from component:', error);
      setError('Error removing media from component');
      toast.error('Error removing media from component');
    }
  };

  const handleModalClose = async () => {
    setEditModalOpen(false);
    try {
      const response = await axios.get('/components');
      setComponents(response.data);
      const updatedComponent = response.data.find((c) => c._id === selectedComponent._id);
      setSelectedComponent(updatedComponent);
    } catch (error) {
      console.error('Error fetching components:', error);
      setError('Error fetching components');
      toast.error('Error fetching components');
    }
  };

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="manage-components-container">
      <h1>Manage Components</h1>
      <label>
        Select Component:
        <select onChange={handleSelectComponent} value={selectedComponent ? selectedComponent._id : ''}>
          <option value="" disabled>Select a component</option>
          {components.map((component) => (
            <option key={component._id} value={component._id}>{component.title}</option>
          ))}
        </select>
      </label>

      {selectedComponent && (
        <div className="component-details">
          <p><strong>Title:</strong> {selectedComponent.title}</p>
          <p><strong>Description:</strong> {selectedComponent.description}</p>
          <p><strong>Unique ID:</strong> {selectedComponent.uniqueId}</p>
          <p><strong>Media:</strong></p>
          <ul>
            {selectedComponent.media.map((mediaId) => {
              const media = mediaList.find(m => m._id === mediaId);
              return (
                <li key={mediaId}>
                  {media ? media.title : 'Media Not Found'}
                  <button onClick={() => handleRemoveMedia(mediaId)}>Remove</button>
                </li>
              );
            })}
          </ul>
          <label>
            Add Media:
            <select onChange={(e) => setSelectedMedia(e.target.value)} value={selectedMedia}>
              <option value="" disabled>Select media</option>
              {mediaList.map((media) => (
                <option key={media._id} value={media._id}>{media.title}</option>
              ))}
            </select>
          </label>
          <button onClick={handleAddMedia}>Add Media</button>
          <button onClick={handleViewDetails}>View Details</button>
          <button onClick={handleEdit}>Edit</button>
          <button onClick={handleDelete}>Delete</button>
        </div>
      )}

      {editModalOpen && selectedComponent && (
        <EditLessonComponent
          componentId={selectedComponent._id}
          onClose={handleModalClose}
        />
      )}
    </div>
  );
};

export default ManageComponents;
