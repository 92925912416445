// Path: /src/pages/admin/ManageUsers.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import axios from '../../config/axios';
import './ManageUsers.css';
import notify from '../../components/CustomToast';

const ManageUsers = () => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [username, setUsername] = useState('');
  const [role, setRole] = useState('');
  const [status, setStatus] = useState('');
  const [locked, setLocked] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [roleFilter, setRoleFilter] = useState('all');

  const navigate = useNavigate(); // Initialize useNavigate

  useEffect(() => {
    fetchUsers();
  }, []);
  
  useEffect(() => {
    const filterUsers = () => {
      let updatedUsers = [...users];

      // Apply search filter by username
      if (searchTerm) {
        updatedUsers = updatedUsers.filter(user =>
          user.username.toLowerCase().includes(searchTerm.toLowerCase())
        );
      }

      // Apply role filter
      if (roleFilter !== 'all') {
        updatedUsers = updatedUsers.filter(user => user.role === roleFilter);
      }

      setFilteredUsers(updatedUsers); // Update the filtered list
    };

    filterUsers(); // Call the filtering function inside useEffect
  }, [searchTerm, roleFilter, users]); // Dependencies

  const fetchUsers = async () => {
    try {
      const response = await axios.get('/users');
      setUsers(response.data);
      setFilteredUsers(response.data); // Initially, filteredUsers = users
    } catch (error) {
      console.error('Error fetching users:', error);
      notify('Error fetching users', 'error');
    }
  };

  const handleSelectUser = (user) => {
    setSelectedUser(user);
    setUsername(user.username);
    setRole(user.role);
    setStatus(user.status);
    setLocked(user.locked);
    setEditMode(false); // Reset edit mode when a user is selected
  };

  const handleEditUser = () => {
    setEditMode(true);
  };

  const handleSaveChanges = async () => {
    try {
      const updatedUser = {
        username,
        role,
        status,
        locked,
      };

      await axios.put(`/users/${selectedUser._id}`, updatedUser);
      notify('User updated successfully', 'success');

      // Update the selectedUser and user list to reflect the changes
      const updatedUsers = users.map(user =>
        user._id === selectedUser._id ? { ...user, ...updatedUser } : user
      );
      setUsers(updatedUsers);
      setSelectedUser({ ...selectedUser, ...updatedUser });

      setEditMode(false); // Exit edit mode after saving
    } catch (error) {
      console.error('Error updating user:', error);
      notify('Error updating user', 'error');
    }
  };

  const handleCancelEdit = () => {
    setEditMode(false);
    setUsername(selectedUser.username);
    setRole(selectedUser.role);
    setStatus(selectedUser.status);
    setLocked(selectedUser.locked);
  };

  const handleDeleteUser = async (userId) => {
    try {
      await axios.delete(`/users/${userId}`);
      notify('User deleted successfully', 'success');
      setSelectedUser(null); // Clear the selected user
      fetchUsers(); // Refresh the list
    } catch (error) {
      console.error('Error deleting user:', error);
      notify('Error deleting user', 'error');
    }
  };

  const handleLockUnlockUser = async (userId) => {
    try {
      await axios.put(`/users/lock-unlock/${userId}`);
      notify(`User ${locked ? 'unlocked' : 'locked'} successfully`, 'success');
      setLocked(!locked); // Toggle locked state
      fetchUsers(); // Refresh the list
    } catch (error) {
      console.error('Error locking/unlocking user:', error);
      notify('Error locking/unlocking user', 'error');
    }
  };

  return (
    <div className="manage-users-container">
      <h1 className="page-header">Manage Users</h1>
      <button className="back-to-dashboard" onClick={() => navigate('/admin-dashboard')}>
        Back to Dashboard
      </button> {/* Add this button */}

      <div className="users-actions-section">
        <div className="users-section">
          <h2 className="section-header">User</h2>
          <hr className="separator" />
          
          {/* Search by username */}
          <form className="search-form" onSubmit={(e) => e.preventDefault()}>
            <input
              type="text"
              placeholder="Search by Name"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </form>

          {/* Filter by role */}
          <div className="filter-form">
            <select value={roleFilter} onChange={(e) => setRoleFilter(e.target.value)}>
              <option value="all">All Roles</option>
              <option value="admin">Admin</option>
              <option value="teacherAdm">Teacher (Admin)</option>
              <option value="teacherNoAdm">Teacher (Non-Admin)</option>
              <option value="student">Student</option>
            </select>
          </div>

          <hr className="separator" />

          {/* User List */}
          <ul className="user-list">
            {filteredUsers.map((user) => (
              <li
                key={user._id}
                onClick={() => handleSelectUser(user)}
                className={selectedUser && selectedUser._id === user._id ? 'selected' : ''}
              >
                {user.username} - {user.role}
              </li>
            ))}
          </ul>
        </div>

        {selectedUser && (
          <div className="actions-section">
            <h2 className="section-header">Actions for User {selectedUser.username}</h2>
            <hr className="separator" />

            {editMode ? (
              <div>
                <label>
                  <strong>Username:</strong>
                  <input
                    type="text"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </label>
                <label>
                  <strong>Role:</strong>
                  <select value={role} onChange={(e) => setRole(e.target.value)}>
                    <option value="admin">Admin</option>
                    <option value="teacherAdm">Teacher (Admin)</option>
                    <option value="teacherNoAdm">Teacher (Non-Admin)</option>
                    <option value="student">Student</option>
                  </select>
                </label>
                <label>
                  <strong>Status:</strong>
                  <select value={status} onChange={(e) => setStatus(e.target.value)}>
                    <option value="active">Active</option>
                    <option value="passive">Passive</option>
                    <option value="locked">Locked</option>
                  </select>
                </label>
                <label>
                  <strong>Locked:</strong>
                  <input
                    type="checkbox"
                    checked={locked}
                    onChange={(e) => setLocked(e.target.checked)}
                  />
                </label>

                <hr className="separator" />
                <button onClick={handleSaveChanges}>Save Changes</button>
                <button onClick={handleCancelEdit}>Cancel</button>
              </div>
            ) : (
              <div>
                <p>
                  <strong>Username:</strong> {selectedUser.username}
                </p>
                <p>
                  <strong>Role:</strong> {selectedUser.role}
                </p>
                <p>
                  <strong>Status:</strong> {selectedUser.status}
                </p>
                <p>
                  <strong>Locked:</strong> {selectedUser.locked ? 'Yes' : 'No'}
                </p>
                <hr className="separator" />
                <button onClick={handleEditUser}>Edit User</button>
                <button onClick={() => handleDeleteUser(selectedUser._id)}>Delete User</button>
                <button onClick={() => handleLockUnlockUser(selectedUser._id)}>
                  {selectedUser.locked ? 'Unlock User' : 'Lock User'}
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ManageUsers;
