// Path: /src/App.js

import React from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom'; 
import Modal from 'react-modal';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Login from './pages/Login';
import ProtectedRoute from './components/ProtectedRoute';
import NotFound from './pages/NotFound'; 
import AdminRoutes from './routes/AdminRoutes';
import StudentRoutes from './routes/StudentRoutes';
import ViewMedia from './pages/ViewMedia'; 
import { useAuth } from './hooks/useAuth';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NotReadyYet from './pages/NotReadyYet';  // Import the placeholder component

Modal.setAppElement('#root');

function App() {
  const { isAuthenticated, userRole, loading } = useAuth();
  const location = useLocation();

  console.log("Current Path:", location.pathname);
  console.log("isAuthenticated:", isAuthenticated);
  console.log("User Role:", userRole);

  // Prevent rendering until loading is done
  if (loading) {
    return <div>Loading...</div>;
  }

  // Prevent infinite redirect loop on login page
  if (location.pathname === "/login" && isAuthenticated) {
    return <Navigate to="/admin-dashboard" replace />;
  }

  return (
    <div className="App">
      {isAuthenticated && <Navbar />}
      <Routes>
        {/* Redirect to login page for base path */}
        <Route path="/" element={<Navigate to="/login" />} />

        {/* Login route */}
        <Route path="/login" element={<Login />} />

        {/* Admin routes */}
        <Route path="/admin-dashboard/*" element={
          <ProtectedRoute allowedRoles={['admin']}>
            <AdminRoutes />
          </ProtectedRoute>
        } />
        <Route path="/admin-dashboard/view-media/:id" element={
          <ProtectedRoute allowedRoles={['admin']}>
            <ViewMedia />
          </ProtectedRoute>
        } />

        {/* Teacher Admin routes (Now placeholder) */}
        <Route path="/teacher-admin-dashboard/*" element={
          <ProtectedRoute allowedRoles={['teacherAdm']}>
            <NotReadyYet />
          </ProtectedRoute>
        } />

        {/* Teacher routes (Now placeholder) */}
        <Route path="/teacher-dashboard/*" element={
          <ProtectedRoute allowedRoles={['teacherNoAdm']}>
            <NotReadyYet />
          </ProtectedRoute>
        } />

        {/* Student routes */}
        <Route path="/student-dashboard/*" element={
          <ProtectedRoute allowedRoles={['student']}>
            <StudentRoutes />
          </ProtectedRoute>
        } />

        {/* Fallback for any unknown route */}
        <Route path="*" element={<NotFound />} />  
      </Routes>
      {isAuthenticated && <Footer />}
      <ToastContainer />
    </div>
  );
}

export default App;
