// Path: /learning-app-frontend/src/components/Navbar.js

import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';

function Navbar() {
  const { isAuthenticated, userRole, logout, loading } = useAuth();

  useEffect(() => {
    console.log("Navbar component mounted");
    console.log("Navbar rendered with state:", { isAuthenticated, userRole, loading });
  }, [isAuthenticated, userRole, loading]);

  if (loading) {
    return null; // Do not render anything while loading
  }

  if (!isAuthenticated) {
    return null; // Do not render the navbar if the user is not authenticated
  }

  return (
    <nav>
      <ul>
        {userRole === 'admin' && (
          <li>
            <Link to="/admin-dashboard">Admin Dashboard</Link>
          </li>
        )}
        {userRole === 'teacherAdm' && (
          <li>
            <Link to="/teacher-admin-dashboard">Teacher Admin Dashboard</Link>
          </li>
        )}
        {userRole === 'teacherNoAdm' && (
          <li>
            <Link to="/teacher-dashboard">Teacher Dashboard</Link>
          </li>
        )}
        {userRole === 'student' && (
          <li>
            <Link to="/student-dashboard">Student Dashboard</Link>
          </li>
        )}
        <li>
          <button onClick={logout}>Logout</button>
        </li>
      </ul>
    </nav>
  );
}

export default Navbar;
