// path / src/components/MediaViewer/MediaViewer.js

import React from 'react';
import TextMediaViewer from './TextMediaViewer';
import VideoMediaViewer from './VideoMediaViewer';

const MediaViewer = ({ media, onClose }) => {
  switch (media.type) {
    case 'text':
      return <TextMediaViewer media={media} onClose={onClose} />;
    case 'video':
      return <VideoMediaViewer media={media} onClose={onClose} />;
   default:
      return <div>Unsupported media type</div>;
  }
};

export default MediaViewer;
