// path/src/pages/student/StudentDashboard.js

import React from 'react';
import { Link } from 'react-router-dom';
import DashboardHeader from '../../components/DashboardHeader';
import './StudentDashboard.css';

const StudentDashboard = () => {
  return (
    <div className="student-dashboard-container">
      <DashboardHeader title="Student Dashboard" />
      <div className="student-dashboard-content">
        <ul>
          <li><Link to="my-learning-paths">My Learning Paths</Link></li>
          <li><Link to="my-progress">My Progress</Link></li>
          <li><Link to="my-profile">My Profile</Link></li>
        </ul>
      </div>
    </div>
  );
};

export default StudentDashboard;
