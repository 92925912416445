// Path: /src/pages/admin/CreateVideoMedia.js

import React, { useState } from 'react';
import axios from '../../config/axios';
import notify from '../../components/CustomToast';
import './CreateVideoMedia.css';

const CreateVideoMedia = ({ onMediaCreated, storageType, selectedFile }) => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [uniqueId, setUniqueId] = useState('');
  const [file, setFile] = useState(null);
  const [compress, setCompress] = useState(false);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('title', title);
    formData.append('description', description);
    formData.append('uniqueId', uniqueId);
    formData.append('compress', compress);

    if (storageType === 'local') {
      if (file) {
        formData.append('file', file);
      } else {
        notify('Please upload a video file', 'error');
        return;
      }
    } else if (storageType === 'remote' && selectedFile) {
      formData.append('fileName', selectedFile.name);
    } else {
      notify('Please select a file', 'error');
      return;
    }

    try {
      await axios.post('/media/video', formData);
      notify('Video media created successfully', 'success');
      onMediaCreated();
    } catch (error) {
      console.error('Error creating video media:', error);
      notify('Error creating video media', 'error');
    }
  };

  return (
    <div className="create-video-media">
      <h2>Create Video Media</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="title">Title</label>
          <input
            type="text"
            id="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="description">Description</label>
          <textarea
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          ></textarea>
        </div>
        <div className="form-group">
          <label htmlFor="unique-id">Unique ID</label>
          <input
            type="text"
            id="unique-id"
            value={uniqueId}
            onChange={(e) => setUniqueId(e.target.value)}
            required
          />
        </div>
        {storageType === 'local' && (
          <div className="form-group">
            <label htmlFor="file">Upload Video File</label>
            <input type="file" id="file" onChange={handleFileChange} />
          </div>
        )}
        {storageType === 'remote' && selectedFile && (
          <div className="form-group">
            <label>Selected File:</label>
            <p>{selectedFile.name}</p>
          </div>
        )}
        <div className="form-group">
          <label>
            <input
              type="checkbox"
              checked={compress}
              onChange={(e) => setCompress(e.target.checked)}
            />
            Compress Video
          </label>
        </div>
        <button type="submit">Upload Video</button>
      </form>
    </div>
  );
};

export default CreateVideoMedia;
