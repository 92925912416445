// path / src/pages/admin/ManageLearningPrograms.js

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../../config/axios';
import './ManageLearningPrograms.css';
import { useAuth } from '../../hooks/useAuth';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ManageLearningPrograms = () => {
  const [learningPrograms, setLearningPrograms] = useState([]);
  const [selectedProgram, setSelectedProgram] = useState(null);
  const { token, isAuthenticated } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchLearningPrograms = async () => {
      if (!token) {
        console.error('Token is missing or null');
        toast.error('Authentication token is missing. Please log in again.');
        return;
      }

      try {
        const response = await axios.get('/learning-programs', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (Array.isArray(response.data)) {
          setLearningPrograms(response.data);
        } else {
          toast.error('Unexpected response format.');
        }
      } catch (error) {
        console.error('Error fetching learning programs:', error);
        toast.error('Error fetching learning programs. Please try again.');
      }
    };

    if (isAuthenticated) {
      fetchLearningPrograms();
    }
  }, [token, isAuthenticated]);

  const handleSelectProgram = (event) => {
    const programId = event.target.value;
    const program = learningPrograms.find((p) => p._id === programId);
    setSelectedProgram(program);
  };

  const handleEdit = () => {
    if (selectedProgram) {
      navigate(`/admin-dashboard/edit-learning-program/${selectedProgram._id}`);
    } else {
      toast.warning('Please select a learning program to edit.');
    }
  };

  const handleDelete = async () => {
    toast(
      ({ closeToast }) => (
        <div>
          <p>Are you sure you want to delete this learning program?</p>
          <div>
            <button
              onClick={async () => {
                try {
                  await axios.delete(`/learning-programs/${selectedProgram._id}`, {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  });
                  setLearningPrograms(learningPrograms.filter(program => program._id !== selectedProgram._id));
                  setSelectedProgram(null);
                  toast.success('Learning Program deleted successfully!');
                  closeToast();
                } catch (error) {
                  console.error('Error deleting learning program:', error);
                  toast.error('Error deleting learning program. Please try again.');
                  closeToast();
                }
              }}
              style={{ marginRight: '8px' }}
            >
              Yes
            </button>
            <button onClick={closeToast}>No</button>
          </div>
        </div>
      ),
      {
        position: "top-center",
        autoClose: false,
        closeOnClick: false,
        closeButton: false,
        draggable: false,
        pauseOnHover: false,
      }
    );
  };

  const handleLockUnlock = async () => {
    try {
      await axios.put(`/learning-programs/lock-unlock/${selectedProgram._id}`, { locked: !selectedProgram.locked }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setLearningPrograms(learningPrograms.map(program =>
        program._id === selectedProgram._id ? { ...program, locked: !program.locked } : program
      ));
      setSelectedProgram({ ...selectedProgram, locked: !selectedProgram.locked });
      toast.success(`Learning Program ${selectedProgram.locked ? 'unlocked' : 'locked'} successfully!`);
    } catch (error) {
      console.error(`Error ${selectedProgram.locked ? 'unlocking' : 'locking'} learning program:`, error);
      toast.error(`Error ${selectedProgram.locked ? 'unlocking' : 'locking'} learning program. Please try again.`);
    }
  };

  return (
    <div className="manage-learning-programs-container">
      <h1>Manage Learning Programs</h1>
      <label>
        Select Learning Program:
        <select onChange={handleSelectProgram} value={selectedProgram ? selectedProgram._id : ''}>
          <option value="" disabled>Select a learning program</option>
          {learningPrograms.map((program) => (
            <option key={program._id} value={program._id}>{program.title}</option>
          ))}
        </select>
      </label>

      {selectedProgram && (
        <div className="program-details">
          <p><strong>Title:</strong> {selectedProgram.title}</p>
          <p><strong>Description:</strong> {selectedProgram.description}</p>
          <p><strong>Unique ID:</strong> {selectedProgram.uniqueId}</p>
          <p><strong>Status:</strong> {selectedProgram.locked ? 'Locked' : 'Unlocked'}</p>
          <button onClick={handleEdit}>Edit</button>
          <button onClick={handleDelete}>Delete</button>
          <button onClick={handleLockUnlock}>{selectedProgram.locked ? 'Unlock' : 'Lock'}</button>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default ManageLearningPrograms;
