// path/src/pages/admin/DeleteLesson.js

import React from 'react';
import axios from '../../config/axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const DeleteLesson = ({ lessonId, onDelete }) => {

  const handleDelete = async () => {
    toast(
      ({ closeToast }) => (
        <div>
          <p>Are you sure you want to delete this lesson?</p>
          <div>
            <button
              onClick={async () => {
                try {
                  await axios.delete(`/lessons/${lessonId}`);
                  onDelete(lessonId);
                  toast.success('Lesson deleted successfully!');
                  closeToast();
                } catch (error) {
                  console.error('Error deleting lesson', error);
                  toast.error('Failed to delete lesson.');
                  closeToast();
                }
              }}
              style={{ marginRight: '8px' }}
            >
              Yes
            </button>
            <button onClick={closeToast}>No</button>
          </div>
        </div>
      ),
      {
        position: "top-center",
        autoClose: false,
        closeOnClick: false,
        closeButton: false,
        draggable: false,
        pauseOnHover: false,
      }
    );
  };

  return (
    <button onClick={handleDelete}>Delete Lesson</button>
  );
};

export default DeleteLesson;
