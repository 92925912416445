// path / src/pages/admin/CreateLesson.js

import React, { useState } from 'react';
import axios from '../../config/axios';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './CreateLesson.css';

const CreateLesson = () => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [uniqueId, setUniqueId] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await axios.post('/lessons', { title, description, uniqueId });
      toast.success('Lesson created successfully!');
      setTitle('');
      setDescription('');
      setUniqueId('');
      navigate('/admin-dashboard/manage-lessons');
    } catch (error) {
      console.error('Error creating lesson', error.response ? error.response.data : error.message);
      toast.error('Error creating lesson. Please try again.');
    }
  };

  return (
    <div className="lesson-form">
      <h1>Create Lesson</h1>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="title">Title</label>
          <input type="text" id="title" value={title} onChange={(e) => setTitle(e.target.value)} required />
        </div>
        <div className="form-group">
          <label htmlFor="description">Description</label>
          <textarea id="description" value={description} onChange={(e) => setDescription(e.target.value)} required></textarea>
        </div>
        <div className="form-group">
          <label htmlFor="uniqueId">Lesson ID (Unique)</label>
          <input type="text" id="uniqueId" value={uniqueId} onChange={(e) => setUniqueId(e.target.value)} required />
        </div>
        <button type="submit" className="submit-btn">Create</button>
      </form>
      <ToastContainer />
    </div>
  );
};

export default CreateLesson;
