//path / src/pages/admin/EditLearningProgram.js

import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from '../../config/axios';
import './EditLearningProgram.css';

const EditLearningProgram = () => {
  const { id } = useParams();
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [uniqueId, setUniqueId] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchLearningProgram = async () => {
      try {
        console.log('Fetching learning program for ID:', id); // Debugging log
        const response = await axios.get(`/learning-programs/${id}`);
        console.log('Fetched learning program:', response.data); // Debugging log
        const { title, description, uniqueId } = response.data;
        setTitle(title);
        setDescription(description);
        setUniqueId(uniqueId);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching learning program:', error);
        setError('Error fetching learning program');
        setLoading(false);
      }
    };

    fetchLearningProgram();
  }, [id]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await axios.put(`/learning-programs/${id}`, { title, description, uniqueId });
      alert('Learning Program updated successfully!');
      navigate('/admin-dashboard/manage-learning-programs'); // Redirect to the manage learning programs page
    } catch (error) {
      console.error('Error updating learning program:', error);
      setError('Error updating learning program');
    }
  };

  const handleCancel = () => {
    navigate('/admin-dashboard/manage-learning-programs');
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="edit-learning-program-container">
      <h1>Edit Learning Program</h1>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="title">Title</label>
          <input type="text" id="title" value={title} onChange={(e) => setTitle(e.target.value)} required />
        </div>
        <div className="form-group">
          <label htmlFor="description">Description</label>
          <textarea id="description" value={description} onChange={(e) => setDescription(e.target.value)} required></textarea>
        </div>
        <div className="form-group">
          <label htmlFor="uniqueId">Unique ID</label>
          <input type="text" id="uniqueId" value={uniqueId} onChange={(e) => setUniqueId(e.target.value)} required />
        </div>
        <div className="form-actions">
          <button type="submit" className="submit-btn">Update</button>
          <button type="button" className="cancel-btn" onClick={handleCancel}>Cancel</button>
        </div>
      </form>
    </div>
  );
};

export default EditLearningProgram;
