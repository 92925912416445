// path / src/pages/admin/ManageLessons.js

import React, { useEffect, useState } from 'react';
import axios from '../../config/axios';
import { useNavigate } from 'react-router-dom';
import './ManageLessons.css';
import DeleteLesson from './DeleteLesson';
import ManageComponents from './ManageComponents';
import AssignToLearningPath from './AssignToLearningPath';
import notify from '../../components/CustomToast';

const ManageLessons = () => {
  const [lessons, setLessons] = useState([]);
  const [selectedLesson, setSelectedLesson] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [lessonsPerPage] = useState(10);
  const [componentSummary, setComponentSummary] = useState('');
  const [learningPathSummary, setLearningPathSummary] = useState('');
  const [openPanel, setOpenPanel] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const lessonResponse = await axios.get('/lessons');
        setLessons(Array.isArray(lessonResponse.data) ? lessonResponse.data : []);
      } catch (error) {
        console.error('Error fetching data', error);
        notify('Error fetching data', 'error');
      }
    };

    fetchData();
  }, []);

 useEffect(() => {
  const fetchData = async () => {
    try {
      console.log('Fetching lessons data...');
      const lessonResponse = await axios.get('/lessons');  // Ensure correct path
      console.log('Lesson API response:', lessonResponse);
      setLessons(Array.isArray(lessonResponse.data) ? lessonResponse.data : []);
    } catch (error) {
      console.error('Error fetching lessons data:', error);
      notify('Error fetching lessons data', 'error');
    }
  };

  fetchData();
}, []);


  const handleSelectLesson = (lesson) => {
    setSelectedLesson(lesson);
    setOpenPanel(null); // Close any open panels when a new lesson is selected
  };

  const handleEdit = () => {
    if (selectedLesson) {
      navigate(`/admin-dashboard/edit-lesson/${selectedLesson._id}`);
    } else {
      notify('Please select a lesson to edit.', 'warning');
    }
  };

  const handleDelete = () => {
    if (selectedLesson) {
      setLessons(lessons.filter((lesson) => lesson._id !== selectedLesson._id));
      setSelectedLesson(null);
    } else {
      notify('Please select a lesson to delete.', 'warning');
    }
  };

  const togglePanel = (panel) => {
    setOpenPanel(openPanel === panel ? null : panel);
  };

  const indexOfLastLesson = currentPage * lessonsPerPage;
  const indexOfFirstLesson = indexOfLastLesson - lessonsPerPage;
  const currentLessons = lessons.slice(indexOfFirstLesson, indexOfLastLesson);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="manage-lessons-container">
      <div className="lesson-list-section">
        <h2>Lesson List</h2>
        <ul>
          {currentLessons.map((lesson) => (
            <li key={lesson._id} onClick={() => handleSelectLesson(lesson)} className={selectedLesson && selectedLesson._id === lesson._id ? 'selected' : ''}>
              {lesson.title}
            </li>
          ))}
        </ul>
        <div className="pagination">
          {Array.from({ length: Math.ceil(lessons.length / lessonsPerPage) }, (_, index) => (
            <button
              key={index + 1}
              onClick={() => paginate(index + 1)}
              disabled={currentPage === index + 1}
            >
              {index + 1}
            </button>
          ))}
        </div>
      </div>

      <div className="lesson-actions-section">
        <h2>Lesson Actions</h2>
        {selectedLesson ? (
          <>
            <p><strong>Title:</strong> {selectedLesson.title}</p>
            <p><strong>Description:</strong> {selectedLesson.description}</p>
            <p><strong>ID:</strong> {selectedLesson.uniqueId}</p>

            <div className="action-buttons">
              <button onClick={handleEdit}>Edit</button>
              <DeleteLesson lessonId={selectedLesson._id} onDelete={handleDelete} />
            </div>

            <div className="action-panel">
              <div className="action-panel-header" onClick={() => togglePanel('components')}>
                {openPanel === 'components' ? '-' : '+'} Component Management
              </div>
              {openPanel === 'components' && (
                <>
                  <div className="action-panel-summary">Linked Components: {componentSummary}</div>
                  <div className="action-panel-body">
                    <ManageComponents lessonId={selectedLesson._id} />
                  </div>
                </>
              )}
            </div>

            <div className="action-panel">
              <div className="action-panel-header" onClick={() => togglePanel('learningPaths')}>
                {openPanel === 'learningPaths' ? '-' : '+'} Lesson Management
              </div>
              {openPanel === 'learningPaths' && (
                <>
                  <div className="action-panel-summary">Linked Learning Paths: {learningPathSummary}</div>
                  <div className="action-panel-body">
                    <AssignToLearningPath lessonId={selectedLesson._id} />
                  </div>
                </>
              )}
            </div>
          </>
        ) : (
          <p>Please select a lesson to view actions.</p>
        )}
      </div>
    </div>
  );
};

export default ManageLessons;

