// path / src/pages/admin/EditMedia.js

import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from '../../config/axios';
import './EditMedia.css';

const EditMedia = () => {
  const { id } = useParams();
  const [media, setMedia] = useState({
    title: '',
    description: '',
    uniqueId: '',
    originalFile: '',
  });
  const [file, setFile] = useState(null);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchMedia = async () => {
      try {
        const response = await axios.get(`/media/${id}`);
        setMedia(response.data);
      } catch (error) {
        console.error('Error fetching media', error);
        setError('Error fetching media');
      }
    };

    fetchMedia();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setMedia((prevMedia) => ({
      ...prevMedia,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSave = async () => {
    const formData = new FormData();
    formData.append('title', media.title);
    formData.append('description', media.description);
    formData.append('uniqueId', media.uniqueId);
    if (file) {
      formData.append('file', file);
    }

    try {
      await axios.put(`/media/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      navigate('/admin-dashboard/manage-media');
    } catch (error) {
      console.error('Error updating media', error);
      setError('Error updating media');
    }
  };

  return (
    <div className="edit-media-container">
      <h1>Edit Media</h1>
      {error && <p className="error-message">{error}</p>}
      <form>
        <div className="form-group">
          <label htmlFor="title">Title</label>
          <input
            type="text"
            id="title"
            name="title"
            value={media.title || ''}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="description">Description</label>
          <textarea
            id="description"
            name="description"
            value={media.description || ''}
            onChange={handleChange}
            required
          ></textarea>
        </div>
        <div className="form-group">
          <label htmlFor="uniqueId">Unique ID</label>
          <input
            type="text"
            id="uniqueId"
            name="uniqueId"
            value={media.uniqueId || ''}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="existingFile">Existing File</label>
          <input
            type="text"
            id="existingFile"
            name="existingFile"
            value={media.originalFile || ''}
            readOnly
          />
        </div>
        <div className="form-group">
          <label htmlFor="file">Replace Media File</label>
          <input
            type="file"
            id="file"
            name="file"
            onChange={handleFileChange}
          />
        </div>
        <button type="button" onClick={handleSave}>Save</button>
        <button type="button" onClick={() => navigate('/admin-dashboard/manage-media')}>Cancel</button>
      </form>
    </div>
  );
};

export default EditMedia;
