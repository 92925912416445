// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src.components.DashboardHeader.css */

.dashboard-header {
  padding: 20px;
  background-color: #f9f9f9;
  border-bottom: 1px solid #ddd;
  margin-bottom: 20px;
}

.dashboard-header h1 {
  font-size: 24px;
  font-weight: bold;
  margin: 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/DashboardHeader.css"],"names":[],"mappings":"AAAA,uCAAuC;;AAEvC;EACE,aAAa;EACb,yBAAyB;EACzB,6BAA6B;EAC7B,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,SAAS;AACX","sourcesContent":["/* src.components.DashboardHeader.css */\r\n\r\n.dashboard-header {\r\n  padding: 20px;\r\n  background-color: #f9f9f9;\r\n  border-bottom: 1px solid #ddd;\r\n  margin-bottom: 20px;\r\n}\r\n\r\n.dashboard-header h1 {\r\n  font-size: 24px;\r\n  font-weight: bold;\r\n  margin: 0;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
