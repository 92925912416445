// path / src/components/MediaViewer/TextMediaViewer.js

import React, { useState, useEffect, useRef } from 'react';
import axios from '../../config/axios';
import './TextMediaViewer.css';

const TextMediaViewer = ({ media, onClose }) => {
  const [notes, setNotes] = useState(media.notes || []);
  const [noteContent, setNoteContent] = useState('');
  const notesRef = useRef(null);

  const handleAddNote = async () => {
    if (noteContent.trim() === '') return;

    try {
      const response = await axios.post(`/media/${media._id}/notes`, { content: noteContent });
      setNotes(response.data.notes);
      setNoteContent('');
    } catch (error) {
      console.error('Error adding note', error);
    }
  };

  const handleUpdateNote = async (noteId, newContent) => {
    if (newContent.trim() === '') return;

    try {
      await axios.put(`/media/${media._id}/notes/${noteId}`, { content: newContent });
      setNotes((prevNotes) =>
        prevNotes.map((note) =>
          note._id === noteId ? { ...note, content: newContent } : note
        )
      );
    } catch (error) {
      console.error('Error updating note', error);
    }
  };

  const handleNoteChange = (noteId, newContent) => {
    setNotes((prevNotes) =>
      prevNotes.map((note) =>
        note._id === noteId ? { ...note, content: newContent } : note
      )
    );
  };

  const handleSaveNotes = async () => {
    for (const note of notes) {
      try {
        await axios.put(`/media/${media._id}/notes/${note._id}`, { content: note.content });
      } catch (error) {
        console.error('Error saving note', error);
      }
    }
  };

  const handleClose = async () => {
    await handleSaveNotes();
    if (noteContent.trim() !== '') {
      await handleAddNote();
    }
    onClose();
  };

  const handleDeleteNote = async (noteId) => {
    try {
      await axios.delete(`/media/${media._id}/notes/${noteId}`);
      setNotes((prevNotes) => prevNotes.filter((note) => note._id !== noteId));
    } catch (error) {
      console.error('Error deleting note', error);
    }
  };

  const handleMinimizeNote = (noteId) => {
    setNotes((prevNotes) =>
      prevNotes.map((note) =>
        note._id === noteId ? { ...note, minimized: !note.minimized } : note
      )
    );
  };

  useEffect(() => {
    const handleScroll = () => {
      if (notesRef.current) {
        const notesSection = notesRef.current;
        const mediaContent = document.querySelector('.media-content');
        const mediaContentRect = mediaContent.getBoundingClientRect();
        const topPosition = Math.max(20, mediaContentRect.top);
        notesSection.style.top = `${topPosition}px`;
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const autoResizeTextarea = (e) => {
    e.target.style.height = 'auto';
    e.target.style.height = `${e.target.scrollHeight}px`;
  };

  useEffect(() => {
    const textareas = document.querySelectorAll('textarea');
    textareas.forEach((textarea) => {
      autoResizeTextarea({ target: textarea });
    });
  }, [notes]);

  return (
    <div className="media-viewer-wrapper">
      <div className="media-content">
        <h3>{media.title}</h3>
        <p>{media.description}</p>
        <div dangerouslySetInnerHTML={{ __html: media.data }}></div>
        <div className="close-button-container">
          <button onClick={handleClose}>Close</button>
        </div>
      </div>

      <div className="notes-section" ref={notesRef}>
        <h4>Notes</h4>
        <ul>
          {notes.map((note) => (
            <li key={note._id} className={note.minimized ? 'minimized' : ''}>
              {!note.minimized && (
                <textarea
                  value={note.content}
                  onChange={(e) => handleNoteChange(note._id, e.target.value)}
                  onBlur={(e) => handleUpdateNote(note._id, e.target.value)}
                  onInput={autoResizeTextarea}
                  rows={1}
                />
              )}
              <div className="note-controls">
                <button onClick={() => handleDeleteNote(note._id)}>
                  <i className="fas fa-trash"></i>
                </button>
                <button onClick={() => handleMinimizeNote(note._id)}>
                  <i className={`fas ${note.minimized ? 'fa-plus' : 'fa-minus'}`}></i>
                </button>
              </div>
            </li>
          ))}
        </ul>
        <textarea
          value={noteContent}
          onChange={(e) => setNoteContent(e.target.value)}
          placeholder="Add a note..."
          onInput={autoResizeTextarea}
          rows={1}
        ></textarea>
        <button onClick={handleAddNote}>Add Note</button>
      </div>
    </div>
  );
};

export default TextMediaViewer;
