// path/src/pages/admin/ViewStudents.js

import React, { useState, useEffect } from 'react';
import axios from '../../config/axios';
import { Link } from 'react-router-dom';
import './ViewStudents.css';

const ViewStudents = () => {
  const [students, setStudents] = useState([]);

  useEffect(() => {
    const fetchStudents = async () => {
      try {
        const response = await axios.get('/students');
        setStudents(response.data);
      } catch (error) {
        console.error('Error fetching students:', error);
      }
    };

    fetchStudents();
  }, []);

  return (
    <div className="view-students-container">
      <h1>View Students</h1>
      <ul>
        {students.map(student => (
          <li key={student._id}>
            <span>{student.username}</span>
            <Link to={`/admin-dashboard/view-students/${student._id}/details`}>View Details</Link>
            <Link to={`/admin-dashboard/view-students/${student._id}/logs`}>View Logs</Link>
            <Link to={`/admin-dashboard/view-students/${student._id}/progress`}>View Progress</Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ViewStudents;
