// path/src/pages/admin/ViewLogs.js
import React, { useEffect, useState } from 'react';
import axios from '../../config/axios';
import './ViewLogs.css';

const ViewLogs = ({ match }) => {
  const [logs, setLogs] = useState([]);
  const [logType, setLogType] = useState('admin'); // Default to 'admin' logs

  useEffect(() => {
    const fetchLogs = async () => {
      try {
        const response = await axios.get(`/logs/${logType}`);
        setLogs(response.data);
      } catch (error) {
        console.error('Error fetching logs:', error);
      }
    };

    fetchLogs();
  }, [logType]);

  return (
    <div className="view-logs-container">
      <h1>View {logType === 'admin' ? 'Admin' : 'Student'} Logs</h1>
      <select onChange={(e) => setLogType(e.target.value)}>
        <option value="admin">Admin Logs</option>
        <option value="student">Student Logs</option>
      </select>
      <ul className="view-logs-list">
        {logs.map((log, index) => (
          <li key={index} className="view-logs-item">
            <h2>{log.title}</h2>
            <p>{log.message}</p>
            <p className="log-timestamp">{new Date(log.timestamp).toLocaleString()}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ViewLogs;
