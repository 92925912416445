// path / src/pages/admin/LinkMediaToComponent.js

import React, { useState, useEffect } from 'react';
import axios from '../../config/axios';
import { useNavigate } from 'react-router-dom';
import notify from '../../components/CustomToast';
import './LinkMediaToComponent.css';

const LinkMediaToComponent = () => {
  const [components, setComponents] = useState([]);
  const [mediaList, setMediaList] = useState([]);
  const [selectedComponent, setSelectedComponent] = useState('');
  const [selectedMedia, setSelectedMedia] = useState('');
  const [linkedMedia, setLinkedMedia] = useState([]);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [componentsResponse, mediaResponse] = await Promise.all([
          axios.get('/components'),
          axios.get('/media')
        ]);

        setComponents(componentsResponse.data);
        setMediaList(mediaResponse.data);
      } catch (error) {
        console.error('Error fetching data', error);
        setError('Error fetching data');
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (selectedComponent) {
      const fetchLinkedMedia = async () => {
        try {
          const populatedComponent = await axios.get(`/components/${selectedComponent}/with-media`);
          setLinkedMedia(populatedComponent.data.media || []);
        } catch (error) {
          console.error('Error fetching linked media', error);
          setError('Error fetching linked media');
        }
      };

      fetchLinkedMedia();
    } else {
      setLinkedMedia([]);
    }
  }, [selectedComponent]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await axios.post(`/components/${selectedComponent}/add-media`, { media: [{ _id: selectedMedia }] });
      notify('Media linked to component successfully!', 'success');
      setSelectedMedia('');
      navigate('/admin-dashboard/manage-components');
    } catch (error) {
      console.error('Error linking media to component', error.response ? error.response.data : error.message);
      if (error.response && error.response.status === 400 && error.response.data.message === 'Media already linked to component') {
        setError('This media is already linked to the selected component.');
        notify('This media is already linked to the selected component.', 'error');
      } else {
        setError(error.response ? error.response.data.message : error.message);
        notify('Error linking media to component', 'error');
      }
    }
  };

  const handleRemoveMedia = async (mediaId) => {
    try {
      await axios.post(`/components/${selectedComponent}/remove-media`, { mediaId });
      setLinkedMedia(linkedMedia.filter(media => media._id !== mediaId));
      notify('Media removed from component successfully!', 'success');
    } catch (error) {
      console.error('Error removing media from component', error);
      notify('Error removing media from component', 'error');
    }
  };

  return (
    <div className="link-form">
      <h1>Link Media to Component</h1>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="component">Select Component</label>
          <select id="component" value={selectedComponent} onChange={(e) => setSelectedComponent(e.target.value)} required>
            <option value="">Select a component</option>
            {components.map((component) => (
              <option key={component._id} value={component._id}>{component.title}</option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="media">Select Media</label>
          <select id="media" value={selectedMedia} onChange={(e) => setSelectedMedia(e.target.value)} required>
            <option value="">Select media</option>
            {mediaList.map((media) => (
              <option key={media._id} value={media._id}>{media.title}</option>
            ))}
          </select>
        </div>
        {error && <p className="error-message">{error}</p>}
        <button type="submit" className="submit-btn">Link</button>
      </form>

      {selectedComponent && linkedMedia && linkedMedia.length > 0 && (
        <div className="linked-media-list">
          <h2>Already Linked Media</h2>
          <ul>
            {linkedMedia.map((media) => (
              <li key={media._id}>
                {media.title}
                <button onClick={() => handleRemoveMedia(media._id)} className="remove-btn">Remove</button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default LinkMediaToComponent;
