// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* path src.pages.admin.ViewLogs.css */

.view-logs-container {
  padding: 20px;
}

.view-logs-container h1 {
  font-size: 2em;
  margin-bottom: 20px;
}

.view-logs-list {
  list-style-type: none;
  padding: 0;
}

.view-logs-item {
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.view-logs-item h2 {
  margin: 0;
  font-size: 1.5em;
}

.view-logs-item p {
  margin: 5px 0;
}

.view-logs-item .log-timestamp {
  font-size: 0.8em;
  color: #888;
}
`, "",{"version":3,"sources":["webpack://./src/pages/admin/ViewLogs.css"],"names":[],"mappings":"AAAA,sCAAsC;;AAEtC;EACE,aAAa;AACf;;AAEA;EACE,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;EACrB,UAAU;AACZ;;AAEA;EACE,mBAAmB;EACnB,aAAa;EACb,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,SAAS;EACT,gBAAgB;AAClB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,gBAAgB;EAChB,WAAW;AACb","sourcesContent":["/* path src.pages.admin.ViewLogs.css */\r\n\r\n.view-logs-container {\r\n  padding: 20px;\r\n}\r\n\r\n.view-logs-container h1 {\r\n  font-size: 2em;\r\n  margin-bottom: 20px;\r\n}\r\n\r\n.view-logs-list {\r\n  list-style-type: none;\r\n  padding: 0;\r\n}\r\n\r\n.view-logs-item {\r\n  margin-bottom: 10px;\r\n  padding: 10px;\r\n  border: 1px solid #ccc;\r\n  border-radius: 4px;\r\n}\r\n\r\n.view-logs-item h2 {\r\n  margin: 0;\r\n  font-size: 1.5em;\r\n}\r\n\r\n.view-logs-item p {\r\n  margin: 5px 0;\r\n}\r\n\r\n.view-logs-item .log-timestamp {\r\n  font-size: 0.8em;\r\n  color: #888;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
