// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* path src.pages.student.StudentDashboard.css */

.student-dashboard-container {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.student-dashboard-content {
  margin-top: 20px;
}

.dashboard-links {
  list-style-type: none;
  padding: 0;
}

.dashboard-links li {
  margin-bottom: 10px;
}

.dashboard-links li a {
  text-decoration: none;
  color: #007bff;
  font-weight: bold;
}

.dashboard-links li a:hover {
  color: #0056b3;
}
`, "",{"version":3,"sources":["webpack://./src/pages/student/StudentDashboard.css"],"names":[],"mappings":"AAAA,gDAAgD;;AAEhD;EACE,aAAa;EACb,yBAAyB;EACzB,kBAAkB;EAClB,uCAAuC;AACzC;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,qBAAqB;EACrB,UAAU;AACZ;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;EACrB,cAAc;EACd,iBAAiB;AACnB;;AAEA;EACE,cAAc;AAChB","sourcesContent":["/* path src.pages.student.StudentDashboard.css */\r\n\r\n.student-dashboard-container {\r\n  padding: 20px;\r\n  background-color: #f9f9f9;\r\n  border-radius: 8px;\r\n  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\r\n}\r\n\r\n.student-dashboard-content {\r\n  margin-top: 20px;\r\n}\r\n\r\n.dashboard-links {\r\n  list-style-type: none;\r\n  padding: 0;\r\n}\r\n\r\n.dashboard-links li {\r\n  margin-bottom: 10px;\r\n}\r\n\r\n.dashboard-links li a {\r\n  text-decoration: none;\r\n  color: #007bff;\r\n  font-weight: bold;\r\n}\r\n\r\n.dashboard-links li a:hover {\r\n  color: #0056b3;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
