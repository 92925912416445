// path / src/pages/admin/CreateLearningProgram.js

import React, { useState } from 'react';
import axios from '../../config/axios';
import './CreateLearningProgram.css';
import { useAuth } from '../../hooks/useAuth';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CreateLearningProgram = () => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [uniqueId, setUniqueId] = useState('');
  const [error, setError] = useState('');
  const { token } = useAuth();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError('');
    try {
      const response = await axios.post('/learning-programs', {
        title,
        description,
        uniqueId
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log('Response headers:', response.config.headers);
      toast.success('Learning program created successfully!');
      setTitle('');
      setDescription('');
      setUniqueId('');
    } catch (error) {
      console.error('Error creating learning program:', error);
      setError('Error creating learning program. Please try again.');
      toast.error('Error creating learning program. Please try again.');
    }
  };

  return (
    <div className="create-learning-program">
      <h1>Create Learning Program</h1>
      <form onSubmit={handleSubmit}>
        <label>
          Title:
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
        </label>
        <label>
          Description:
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          />
        </label>
        <label>
          Unique ID:
          <input
            type="text"
            value={uniqueId}
            onChange={(e) => setUniqueId(e.target.value)}
            required
          />
        </label>
        {error && <p>{error}</p>}
        <button type="submit">Create</button>
      </form>
      <ToastContainer /> {/* This is where the toast notifications will be displayed */}
    </div>
  );
};

export default CreateLearningProgram;
