// path / src/pages/admin/LinkComponentToLesson.js

import React, { useState, useEffect } from 'react';
import axios from '../../config/axios';
import notify from '../../components/CustomToast'; // Import the notify function
import './LinkComponentToLesson.css';

const LinkComponentToLesson = () => {
  const [lessons, setLessons] = useState([]);
  const [components, setComponents] = useState([]);
  const [selectedLessonId, setSelectedLessonId] = useState('');
  const [selectedComponentId, setSelectedComponentId] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchLessonsAndComponents = async () => {
      try {
        const lessonResponse = await axios.get('/lessons');
        const componentResponse = await axios.get('/components');
        setLessons(lessonResponse.data);
        setComponents(componentResponse.data);
      } catch (error) {
        console.error('Error fetching lessons and components', error);
        setError('Error fetching lessons and components');
        notify('Error fetching lessons and components', 'error');
      }
    };

    fetchLessonsAndComponents();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await axios.post(`/lessons/${selectedLessonId}/link-component`, { componentId: selectedComponentId });
      notify('Component linked to lesson successfully!', 'success');
      setSelectedLessonId('');
      setSelectedComponentId('');
    } catch (error) {
      console.error('Error linking component to lesson', error.response ? error.response.data : error.message);
      setError(error.response ? error.response.data.message : error.message);
      notify(`Error linking component to lesson: ${error.response ? error.response.data.message : error.message}`, 'error');
    }
  };

  return (
    <div className="link-component-form">
      <h2>Link Component to Lesson</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="lesson">Select Lesson</label>
          <select
            id="lesson"
            value={selectedLessonId}
            onChange={(e) => setSelectedLessonId(e.target.value)}
            required
          >
            <option value="">Select a lesson</option>
            {lessons.map((lesson) => (
              <option key={lesson._id} value={lesson._id}>
                {lesson.title}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="component">Select Component</label>
          <select
            id="component"
            value={selectedComponentId}
            onChange={(e) => setSelectedComponentId(e.target.value)}
            required
          >
            <option value="">Select a component</option>
            {components.map((component) => (
              <option key={component._id} value={component._id}>
                {component.title}
              </option>
            ))}
          </select>
        </div>
        {error && <p className="error-message">{error}</p>}
        <button type="submit" className="submit-btn">Link</button>
      </form>
    </div>
  );
};

export default LinkComponentToLesson;
