// path/src/pages/admin/StudentDetails.js

import React, { useState, useEffect } from 'react';
import axios from '../../config/axios';
import { useParams } from 'react-router-dom';
import './StudentDetails.css';

const StudentDetails = () => {
  const { id } = useParams();
  const [student, setStudent] = useState(null);
  const [message, setMessage] = useState('');

  useEffect(() => {
    const fetchStudentDetails = async () => {
      try {
        const response = await axios.get(`/students/${id}`);
        setStudent(response.data);
      } catch (error) {
        console.error('Error fetching student details:', error);
        setMessage('Error fetching student details');
      }
    };

    fetchStudentDetails();
  }, [id]);

  if (!student) {
    return <p>Loading...</p>;
  }

  return (
    <div className="student-details">
      <h1>Student Details</h1>
      {message && <p>{message}</p>}
      <p><strong>Username:</strong> {student.username}</p>
      <p><strong>Role:</strong> {student.role}</p>
      <p><strong>Creator:</strong> {student.creator?.username}</p>
      {/* Add other student details as needed */}
    </div>
  );
};

export default StudentDetails;
