// path / src/pages/admin/EditLearningPath.js

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import axios from '../../config/axios';
import './EditLearningPath.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const EditLearningPath = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [learningPrograms, setLearningPrograms] = useState([]);
  const [selectedProgram, setSelectedProgram] = useState('');
  const [learningPathId, setLearningPathId] = useState('');

  useEffect(() => {
    if (location.state && location.state.selectedPath) {
      const { title, description, learningProgram, learningPathId } = location.state.selectedPath;
      setTitle(title);
      setDescription(description);
      setSelectedProgram(learningProgram._id);
      setLearningPathId(learningPathId);
    } else {
      const fetchLearningPath = async () => {
        try {
          const response = await axios.get(`/admin/learning-paths/${id}`);
          const { title, description, learningProgram, learningPathId } = response.data;
          setTitle(title || '');
          setDescription(description || '');
          setSelectedProgram(learningProgram?._id || '');
          setLearningPathId(learningPathId || '');
        } catch (error) {
          console.error('Error fetching learning path:', error);
          toast.error('Error fetching learning path');
        }
      };

      fetchLearningPath();
    }

    const fetchLearningPrograms = async () => {
      try {
        const response = await axios.get('/learning-programs');
        setLearningPrograms(Array.isArray(response.data) ? response.data : []);
      } catch (error) {
        console.error('Error fetching learning programs:', error);
        toast.error('Error fetching learning programs');
      }
    };

    fetchLearningPrograms();
  }, [id, location.state]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await axios.put(`/admin/learning-paths/${id}`, { title, description, learningProgram: selectedProgram, learningPathId });
      toast.success('Learning Path updated successfully!');
      navigate('/admin-dashboard/manage-learning-paths');
    } catch (error) {
      console.error('Error updating learning path:', error);
      toast.error('Error updating learning path');
    }
  };

  const handleCancel = () => {
    navigate('/admin-dashboard/manage-learning-paths');
  };

  return (
    <div className="edit-learning-path-container">
      <h1>Edit Learning Path</h1>
      <form onSubmit={handleSubmit}>
        <label>
          Title:
          <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} required />
        </label>
        <br />
        <label>
          Description:
          <textarea value={description} onChange={(e) => setDescription(e.target.value)} required />
        </label>
        <br />
        <label>
          Learning Program:
          <select value={selectedProgram} onChange={(e) => setSelectedProgram(e.target.value)} required>
            <option value="" disabled>Select a learning program</option>
            {learningPrograms.map((program) => (
              <option key={program._id} value={program._id}>{program.title}</option>
            ))}
          </select>
        </label>
        <br />
        <label>
          Unique Learning Path ID:
          <input type="text" value={learningPathId} onChange={(e) => setLearningPathId(e.target.value)} required />
        </label>
        <br />
        <button type="submit">Update</button>
        <button type="button" onClick={handleCancel}>Cancel</button>
      </form>
      <ToastContainer />
    </div>
  );
};

export default EditLearningPath;
