// path / src/pages/admin/StudentProgress.js

import React, { useState, useEffect } from 'react';
import axios from '../../config/axios';
import { useParams } from 'react-router-dom';
import './StudentProgress.css';

const StudentProgress = () => {
  const { id } = useParams();
  const [progress, setProgress] = useState([]);
  const [student, setStudent] = useState(null);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchProgress = async () => {
      try {
        const response = await axios.get(`/students/${id}/progress`);
        if (Array.isArray(response.data.progress)) {
          setProgress(response.data.progress);
        } else {
          setProgress([]);
        }
        setStudent(response.data.student);
      } catch (error) {
        console.error('Error fetching progress:', error);
        setError('Error fetching progress');
      }
    };

    fetchProgress();
  }, [id]);

  return (
    <div className="student-progress">
      <h1>Student Progress</h1>
      {error && <p className="error">{error}</p>}
      {student && (
        <div className="student-details">
          <h2>{student.username}</h2>
          <p>Email: {student.email}</p>
          <p>Role: {student.role}</p>
        </div>
      )}
      {progress.length > 0 ? (
        <table>
          <thead>
            <tr>
              <th>Lesson Title</th>
              <th>Progress</th>
            </tr>
          </thead>
          <tbody>
            {progress.map((item) => (
              <tr key={item.lessonId}>
                <td>{item.lessonTitle}</td>
                <td>{item.progress}%</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No progress data available.</p>
      )}
    </div>
  );
};

export default StudentProgress;
