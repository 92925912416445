// Path: /src/pages/admin/CreateMediaPage.js

import React, { useState } from 'react';
import CreatePdfMedia from './CreatePdfMedia';
import CreateTextMedia from './CreateTextMedia';
import CreateVideoMedia from './CreateVideoMedia';
import './CreateMediaPage.css'; // General page layout CSS

const CreateMediaPage = () => {
  const [selectedMediaType, setSelectedMediaType] = useState(null);

  // Function to render the appropriate media creation form based on the selected type
  const renderMediaForm = () => {
    switch (selectedMediaType) {
      case 'pdf':
        return <CreatePdfMedia />;
      case 'text':
        return <CreateTextMedia />;
      case 'video':
        return <CreateVideoMedia />;
      default:
        return <p>Please select a media type to create.</p>;
    }
  };

  return (
    <div className="create-media-container">
      <h1 className="page-title">Create Media</h1>
      <div className="separator-pad"></div>
      <div className="sections-wrapper">
        <div className="media-selection-section">
          <h2>Select Media Type</h2>
          <button onClick={() => setSelectedMediaType('text')}>Odt-Text Media</button>
          <button onClick={() => setSelectedMediaType('pdf')}>PDF Media</button>
          <button onClick={() => setSelectedMediaType('video')}>Video Media</button>
        </div>
        <div className="separator"></div>
        <div className="media-form-section">
          {renderMediaForm()}
        </div>
      </div>
    </div>
  );
};

export default CreateMediaPage;
