// Path: /src/routes/AdminRoutes.js


import React from 'react';
import { Routes, Route } from 'react-router-dom';
import AdminDashboard from '../pages/admin/AdminDashboard';
import CreateUser from '../pages/admin/CreateUser';
import ManageUsers from '../pages/admin/ManageUsers';
import CreateLearningProgram from '../pages/admin/CreateLearningProgram';
import ManageLearningPrograms from '../pages/admin/ManageLearningPrograms';
import CreateLearningPath from '../pages/admin/CreateLearningPath';
import ManageLearningPaths from '../pages/admin/ManageLearningPaths';
import AssignLearningPath from '../pages/admin/AssignLearningPath';
import ViewLearningPaths from '../pages/admin/ViewLearningPaths';
import ViewAssignedLearningPaths from '../pages/admin/ViewAssignedLearningPaths';
import RemoveLearningPathAssignment from '../pages/admin/RemoveLearningPathAssignment';
import CreateLesson from '../pages/admin/CreateLesson';
import ManageLessons from '../pages/admin/ManageLessons';
import ViewLessons from '../pages/admin/ViewLessons';
import LessonDetails from '../pages/admin/LessonDetails';
import EditLesson from '../pages/admin/EditLesson';
import ViewStudents from '../pages/admin/ViewStudents';
import ViewProgress from '../pages/admin/ViewProgress';
import ViewLogs from '../pages/admin/ViewLogs';
import StudentLogs from '../pages/admin/StudentLogs';
import StudentDetails from '../pages/admin/StudentDetails';
import StudentProgress from '../pages/admin/StudentProgress';
import AddContentToLesson from '../pages/admin/AddContentToLesson';
import EditLearningProgram from '../pages/admin/EditLearningProgram';
import CreateLessonComponent from '../pages/admin/CreateLessonComponent';
import LinkComponentToLesson from '../pages/admin/LinkComponentToLesson';
import EditLessonComponent from '../pages/admin/EditLessonComponent';
import CreateMediaPage from '../pages/admin/CreateMediaPage'; 
import CreateTextMedia from '../pages/admin/CreateTextMedia';
import ManageMedia from '../pages/admin/ManageMedia';
import LinkMediaToComponent from '../pages/admin/LinkMediaToComponent';
import EditLearningPath from '../pages/admin/EditLearningPath';
import ViewMedia from '../pages/admin/ViewMedia';
import EditMedia from '../pages/admin/EditMedia';
import ManageComponents from '../pages/admin/ManageComponents';
import CompressVideoPage from '../pages/admin/CompressVideoPage';
import useScrollToTop from '../hooks/useScrollToTop';

const AdminRoutes = () => {
  useScrollToTop();

  return (
    <Routes>
      {/* Admin Dashboard as the base page */}
      <Route path="/" element={<AdminDashboard />} />
      
      {/* Routes under /admin-dashboard */}
      <Route path="create-user" element={<CreateUser />} />
      <Route path="manage-users" element={<ManageUsers />} />
      <Route path="create-learning-program" element={<CreateLearningProgram />} />
      <Route path="manage-learning-programs" element={<ManageLearningPrograms />} />
      <Route path="edit-learning-program/:id" element={<EditLearningProgram />} />
      <Route path="create-learning-path" element={<CreateLearningPath />} />
      <Route path="manage-learning-paths" element={<ManageLearningPaths />} />
      <Route path="assign-learning-path" element={<AssignLearningPath />} />
      <Route path="view-learning-paths" element={<ViewLearningPaths />} />
      <Route path="edit-learning-path/:id" element={<EditLearningPath />} />
      <Route path="view-assigned-learning-paths" element={<ViewAssignedLearningPaths />} />
      <Route path="remove-learning-path-assignment" element={<RemoveLearningPathAssignment />} />
      <Route path="create-lesson" element={<CreateLesson />} />
      <Route path="add-content-to-lesson" element={<AddContentToLesson />} />
      <Route path="manage-lessons" element={<ManageLessons />} />
      <Route path="view-lessons" element={<ViewLessons />} />
      <Route path="view-lessons/:id" element={<LessonDetails />} />
      <Route path="edit-lesson/:lessonId" element={<EditLesson />} />
      <Route path="view-students" element={<ViewStudents />} />
      <Route path="view-progress" element={<ViewProgress />} />
      <Route path="view-logs" element={<ViewLogs />} />
      <Route path="student-logs" element={<StudentLogs />} />
      <Route path="view-student/:id" element={<StudentDetails />} />
      <Route path="view-progress/:id" element={<StudentProgress />} />
      <Route path="create-lesson-component" element={<CreateLessonComponent />} />
      <Route path="link-component-to-lesson" element={<LinkComponentToLesson />} />
      <Route path="edit-lesson-component/:id" element={<EditLessonComponent />} />
      <Route path="create-media" element={<CreateMediaPage />} />
      <Route path="manage-media" element={<ManageMedia />} />
      <Route path="link-media-to-component" element={<LinkMediaToComponent />} />
      <Route path="view-media" element={<ViewMedia />} />
      <Route path="edit-media/:id" element={<EditMedia />} />
      <Route path="manage-components" element={<ManageComponents />} />
      <Route path="compress-video" element={<CompressVideoPage />} />
    </Routes>
  );
};

export default AdminRoutes;
