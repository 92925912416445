// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* path src.components.AdminNavigation.css */

.nav-group {
  margin-bottom: 20px;
}

.nav-group h3 {
  font-size: 1.2em;
  margin-bottom: 10px;
}

.nav-group ul {
  list-style-type: none;
  padding-left: 0;
}

.nav-group ul li {
  margin-bottom: 5px;
}

.nav-group ul li a {
  text-decoration: none;
  color: #007bff;
}

.nav-group ul li a:hover {
  text-decoration: underline;
}
`, "",{"version":3,"sources":["webpack://./src/components/AdminNavigation.css"],"names":[],"mappings":"AAAA,4CAA4C;;AAE5C;EACE,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;EACrB,eAAe;AACjB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,qBAAqB;EACrB,cAAc;AAChB;;AAEA;EACE,0BAA0B;AAC5B","sourcesContent":["/* path src.components.AdminNavigation.css */\r\n\r\n.nav-group {\r\n  margin-bottom: 20px;\r\n}\r\n\r\n.nav-group h3 {\r\n  font-size: 1.2em;\r\n  margin-bottom: 10px;\r\n}\r\n\r\n.nav-group ul {\r\n  list-style-type: none;\r\n  padding-left: 0;\r\n}\r\n\r\n.nav-group ul li {\r\n  margin-bottom: 5px;\r\n}\r\n\r\n.nav-group ul li a {\r\n  text-decoration: none;\r\n  color: #007bff;\r\n}\r\n\r\n.nav-group ul li a:hover {\r\n  text-decoration: underline;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
