// Path: /src/pages/admin/CreatePdfMedia.js

import React, { useState, useEffect } from 'react';
import axios from '../../config/axios';
import './CreatePdfMedia.css';
import notify from '../../components/CustomToast';

const CreatePdfMedia = () => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [uniqueId, setUniqueId] = useState('');
  const [fileName, setFileName] = useState('');
  const [error, setError] = useState('');
  const [files, setFiles] = useState([]); // Only contains PDF files
  const [useLocalFile, setUseLocalFile] = useState(false);
  const [selectedLocalFile, setSelectedLocalFile] = useState(null);

  // Fetch PDF files from the server
  useEffect(() => {
    const fetchMediaFiles = async () => {
      try {
        const response = await axios.get('/media/list-media-files');
        setFiles(response.data.filter(file => file.name.endsWith('.pdf'))); // Only show PDF files
      } catch (error) {
        console.error('Error fetching media files:', error);
        notify('Error fetching media files', 'error');
      }
    };
    fetchMediaFiles();
  }, []);

  const resetForm = () => {
    setTitle('');
    setDescription('');
    setUniqueId('');
    setFileName('');
    setSelectedLocalFile(null);
    setError('');
    setUseLocalFile(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!title || !description || !uniqueId || (!fileName && !useLocalFile)) {
      setError('All fields are required');
      notify('All fields are required', 'error');
      return;
    }

    const formData = new FormData();
    formData.append('title', title);
    formData.append('description', description);
    formData.append('uniqueId', uniqueId);
    formData.append('mediaId', uniqueId);
    formData.append('mediaIndex', 0);

    if (useLocalFile && selectedLocalFile) {
      formData.append('file', selectedLocalFile);
    } else {
      formData.append('fileName', fileName);
    }

    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };

      await axios.post(`/media/pdf`, formData, config);
      notify('PDF media created successfully!', 'success');
      resetForm();
    } catch (error) {
      console.error('Error creating PDF media:', error);
      setError('Error creating PDF media');
      notify('Error creating PDF media', 'error');
    }
  };

  return (
    <div className="media-form-section">
      <h2>Create PDF Media</h2>
      <form className="media-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="title">Title</label>
          <input
            type="text"
            id="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="description">Description</label>
          <textarea
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          ></textarea>
        </div>
        <div className="form-group">
          <label htmlFor="uniqueId">Unique ID</label>
          <input
            type="text"
            id="uniqueId"
            value={uniqueId}
            onChange={(e) => setUniqueId(e.target.value)}
            required
          />
        </div>
        {!useLocalFile && (
          <div className="form-group">
            <label htmlFor="fileName">Select File</label>
            <select
              id="fileName"
              value={fileName}
              onChange={(e) => setFileName(e.target.value)}
              required
            >
              <option value="">Choose a file</option>
              {files.map((file, index) => (
                <option key={index} value={file.name}>
                  {file.name}
                </option>
              ))}
            </select>
          </div>
        )}
        {useLocalFile && (
          <div className="form-group">
            <label htmlFor="file">Upload Local File</label>
            <input
              type="file"
              id="file"
              onChange={(e) => setSelectedLocalFile(e.target.files[0])}
              required
            />
          </div>
        )}
        <div className="form-group">
          <label>
            <input
              type="checkbox"
              checked={useLocalFile}
              onChange={() => setUseLocalFile(!useLocalFile)}
            />
            Use Local File
          </label>
        </div>
        {error && <p className="error-message">{error}</p>}
        <button type="submit" className="submit-btn">
          Create PDF Media
        </button>
      </form>
    </div>
  );
};

export default CreatePdfMedia;
