// Path: /src/components/AdminNavigation.js

import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import './AdminNavigation.css';

console.log("AdminNavigation component file loaded");

const adminRoutes = [
  {
    category: 'Users',
    links: [
      { path: '/admin-dashboard/create-user', label: 'Create User' },
      { path: '/admin-dashboard/manage-users', label: 'Manage Users' },
    ],
  },
  {
    category: 'Learning Programs',
    links: [
      { path: '/admin-dashboard/create-learning-program', label: 'Create Learning Program' },
      { path: '/admin-dashboard/manage-learning-programs', label: 'Manage Learning Programs' },
    ],
  },
  {
    category: 'Learning Paths',
    links: [
      { path: '/admin-dashboard/create-learning-path', label: 'Create Learning Path' },
      { path: '/admin-dashboard/manage-learning-paths', label: 'Manage Learning Paths' },
      { path: '/admin-dashboard/assign-learning-path', label: 'Assign Learning Path' },
      { path: '/admin-dashboard/view-learning-paths', label: 'View Learning Paths' },
      { path: '/admin-dashboard/view-assigned-learning-paths', label: 'View Assigned Learning Paths' },
      { path: '/admin-dashboard/remove-learning-path-assignment', label: 'Remove Learning Path Assignment' },
    ],
  },
  {
    category: 'Lessons',
    links: [
      { path: '/admin-dashboard/create-lesson', label: 'Create Lesson' },
      { path: '/admin-dashboard/manage-lessons', label: 'Manage Lessons' },
      { path: '/admin-dashboard/view-lessons', label: 'View Lessons' },
      { path: '/admin-dashboard/create-lesson-component', label: 'Create Lesson Component' },
      { path: '/admin-dashboard/manage-components', label: 'Manage Components' },
      { path: '/admin-dashboard/link-component-to-lesson', label: 'Link Component to Lesson' },
      { path: '/admin-dashboard/create-media', label: 'Create Media' },
      { path: '/admin-dashboard/manage-media', label: 'Manage Media' },
      { path: '/admin-dashboard/view-media', label: 'View Media' },
      { path: '/admin-dashboard/link-media-to-component', label: 'Link Media to Component' },
      { path: '/admin-dashboard/create-media-basic', label: 'Create Media Basic' },
    ],
  },
  {
    category: 'Students',
    links: [
      { path: '/admin-dashboard/view-students', label: 'View Students' },
      { path: '/admin-dashboard/view-progress', label: 'View Progress' },
      { path: '/admin-dashboard/student-logs', label: 'View Student Logs' },
    ],
  },
  {
    category: 'Logs',
    links: [
      { path: '/admin-dashboard/view-logs', label: 'View Logs' },
      { path: '/admin-dashboard/student-logs', label: 'Student Logs' },
    ],
  },
  {
    category: 'General',
    links: [
      { path: '/admin-dashboard', label: 'Dashboard' },
      { path: '/admin-dashboard/logout', label: 'Logout' },
    ],
  },
];

const AdminNavigation = () => {
  const { isAuthenticated, userRole, loading, token } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('username');
    sessionStorage.removeItem('role');
    navigate('/login');
  };

  if (loading) {
    console.log('AdminNavigation: Still loading, not rendering');
    return null;
  }

  if (!isAuthenticated || userRole !== 'admin') {
    console.log('AdminNavigation - Not rendering, isAuthenticated:', isAuthenticated, 'userRole:', userRole);
    return <div>Loading...</div>; 
  }

  console.log('AdminNavigation: Rendering admin routes');
  console.log('Token:', token); // Log token for debugging

  return (
    <nav>
      {adminRoutes.map((routeGroup) => (
        <div key={routeGroup.category} className="nav-group">
          <h3>{routeGroup.category}</h3>
          <ul>
            {routeGroup.links.map((route) => (
              <li key={route.path}>
                {route.path === '/admin-dashboard/logout' ? (
                  <button onClick={handleLogout}>{route.label}</button>
                ) : (
                  <Link to={route.path}>{route.label}</Link>
                )}
              </li>
            ))}
          </ul>
        </div>
      ))}
    </nav>
  );
};

export default AdminNavigation;
