// path / src/pages/admin/AssignToLearningPath.js

import React, { useState, useEffect } from 'react';
import axios from '../../config/axios';
import './AssignToLearningPath.css';
import notify from '../../components/CustomToast';

const AssignToLearningPath = ({ lessonId }) => {
  const [learningPaths, setLearningPaths] = useState([]);
  const [selectedLearningPath, setSelectedLearningPath] = useState('');

  useEffect(() => {
    const fetchLearningPaths = async () => {
      try {
        const response = await axios.get('/learning-paths');
        setLearningPaths(Array.isArray(response.data) ? response.data : []);
      } catch (error) {
        console.error('Error fetching learning paths', error);
        notify('Error fetching learning paths', 'error');
      }
    };

    fetchLearningPaths();
  }, []);

  const handleAssignToLearningPath = async () => {
    if (!selectedLearningPath) {
      notify('Please select a learning path to assign.', 'warning');
      return;
    }

    try {
      await axios.post('/admin/learning-paths/add-lesson', {
        learningPathId: selectedLearningPath,
        lessonId,
      });
      notify('Lesson assigned to learning path successfully!', 'success');
    } catch (error) {
      console.error('Error assigning lesson to learning path:', error);
      notify('Error assigning lesson to learning path. Please try again.', 'error');
    }
  };

  return (
    <div className="assign-learning-paths-container">
      <h2>Assign Lesson to Learning Path</h2>
      <label>
        Select Learning Path:
        <select
          value={selectedLearningPath}
          onChange={(e) => setSelectedLearningPath(e.target.value)}
        >
          <option value="">Select a learning path</option>
          {learningPaths.map((path) => (
            <option key={path._id} value={path._id}>
              {path.title}
            </option>
          ))}
        </select>
      </label>
      <button onClick={handleAssignToLearningPath}>Assign</button>
    </div>
  );
};

export default AssignToLearningPath;
